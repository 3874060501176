import { useQuery } from '@apollo/client';
import { normalizedAddress } from 'services/apollo/queries';
import {
  NormalizedAddress,
  NormalizedAddressVariables,
} from 'types/generated/NormalizedAddress';
import { Address } from 'types';
import { useContext } from 'react';
import { MicrositeContext } from 'context';

/**
 * Retrieve the available offers from serviceability with gql.
 * @returns QueryResult
 */

export function useNormalizedAddressQuery(address: Address) {
  const micrositeConfig = useContext(MicrositeContext);
  // Because we store the address in zustand, and zustand reads are async,
  // this query can fire before we've first written the address to the zustand
  // store. The microsite might also be configured to skip this normalization.
  // Add a skip check:
  const skip =
    micrositeConfig?.suppressNormalizedAddressCheck ||
    (address.city === '' &&
      address.state === '' &&
      address.postalCode === '' &&
      address.street === '' &&
      address.unit === '');
  const addressQuery = useQuery<NormalizedAddress, NormalizedAddressVariables>(
    normalizedAddress,
    {
      variables: {
        input: {
          city: address.city,
          postalCode: address.postalCode,
          state: address.state,
          street: address.street,
          unit: address.unit,
        },
      },
      skip,
    }
  );

  return addressQuery;
}
