import { gql } from '@apollo/client';

export const createLeadMutation = gql`
  mutation createLead($input: CreateLeadInput) {
    createLead(input: $input) {
      leadId
      confirmationPhoneNumber
      address {
        postalCode
        state
        city
        street
        unit
      }
    }
  }
`;
