import { FC, useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';

import { useSession } from '@updater/auth';
import { makeClient } from '@updater/ui-utilities';
import { useEnvironment } from '../environment';
import { config } from '../auth';

export const ApolloClient: FC = ({ children }) => {
  const removeTypenameLink = removeTypenameFromVariables();
  const env = useEnvironment();
  const { session } = useSession();

  const client = useMemo(() => {
    console.log('[ApolloClient]', env.apolloUrl);
    console.log('[ApolloClient] session', session);
    return makeClient({
      url: env.apolloUrl,
      name: 'provider-app',
      requestHeaders: () => ({ ...config, ...session }),
      links: [removeTypenameLink],
    });
  }, [config, session, env.apolloUrl]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
