/* eslint-disable global-require */

export function prepare() {
  if (typeof window === 'undefined') {
    const { server } = require('./server');
    return server.listen();
  }
  const { worker } = require('./browser');
  return worker.start({
    onUnhandledRequest: 'bypass',
    serviceWorker: {
      url: '/mockServiceWorker.js',
      options: {
        scope: '/',
      },
    },
  });
}
