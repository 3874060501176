/* eslint-disable @typescript-eslint/no-shadow */
import { rest, graphql } from 'msw';
import { availableOffers } from './data/availableOffers';

const env = {
  EVENT_STREAM_URL:
    process.env.EVENT_STREAM_URL ??
    'https://eventstream-api.staging.infra.updatron.com/xyz',
};

// Follow the examples in (https://mswjs.io/docs/basics/request-handler)
export const handlers = [
  /**
   * Trigger event in the event stream
   */
  rest.post(env.EVENT_STREAM_URL, (_, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),

  /**
   * gql query for offers from s12y
   */
  graphql.query('availableOffers', (req, res, ctx) => {
    return res(ctx.delay(2500), ctx.status(200), ctx.data(availableOffers));
  }),
];
