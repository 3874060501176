import { useTracking as useReactTracking } from 'react-tracking';
import { TrackEventInput } from '@updater/ui-tracker';
import { useEffect } from 'react';
import { Events } from './tracking.types';
import { appName } from './constants';

/**
 * Custom hook that returns a callback that simplifies
 * triggering page tracking events.
 *
 * ```
 * usePageEvent(Events.PLANS);
 * ```
 *
 * @returns void
 */
export function usePageEvent(event: Events, deps: Array<any> = []) {
  const tracker = useReactTracking<TrackEventInput<unknown>>();
  useEffect(() => {
    tracker.trackEvent({
      domain: appName,
      object: event,
      verb: 'viewed',
    });
  }, deps);
}
