import { ProviderConfig, ProviderTypes } from 'types';
import { TDSTheme } from 'config/themes';

export const TDS: ProviderConfig = {
  providerType: ProviderTypes.MSO,
  providerCode: 'tds',
  dcCode: 'TDS',
  name: 'TDS',
  displayName: 'TDS',
  reskinTheme: TDSTheme,
  reskinTitle: 'TDS Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'updater_generated',
};
