import { ATTTheme } from './ATTTheme';
import { AltafiberTheme } from './AltafiberTheme';
import { ApartmentGuideTheme } from './ApartmentGuideTheme';
import { ApartmentListTheme } from './ApartmentListTheme';
import { AstoundTheme } from './AstoundTheme';
import { BandwidthBuildersTheme } from './BandwidthBuildersTheme';
import { BrightspeedTheme } from './BrightspeedTheme';
import { BuckeyeCableTheme } from './BuckeyeCableTheme';
import { CenturyLinkTheme } from './CenturyLinkTheme';
import { CoxTheme } from './CoxTheme';
import { DefaultTheme } from './DefaultTheme';
import { DirecTVTheme } from './DirecTVTheme';
import { DishIFramedTheme } from './DishIFramedTheme';
import { DishTheme } from './DishTheme';
import { EarthlinkTheme } from './EarthlinkTheme';
import { FrontierTheme } from './Frontier';
import { GrandeTheme } from './Grande';
import { MediacomTheme } from './Mediacom';
import { OptimumTheme } from './OptimumTheme';
import { QuantumFiberTheme } from './QuantumTheme';
import { RCNTheme } from './RCNTheme';
import { SpectrumTheme } from './SpectrumTheme';
import { TDSTheme } from './TDSTheme/theme';
import { TXUTheme } from './TXUTheme/theme';
import { TurbotenantTheme } from './TurbotenantTheme';
import { UpdaterTheme } from './UpdaterTheme';
import { VerizonTheme } from './VerizonTheme';
import { WOWTheme } from './WOWTheme';
import { WaveTheme } from './WaveTheme';
import { WindstreamTheme } from './WindstreamTheme';
import { XfinityTheme } from './XfinityTheme';

export const ThemeList = [
  DefaultTheme,
  XfinityTheme,
  ATTTheme,
  UpdaterTheme,
  AltafiberTheme,
  BrightspeedTheme,
  ApartmentGuideTheme,
  ApartmentListTheme,
  BandwidthBuildersTheme,
  BuckeyeCableTheme,
  EarthlinkTheme,
  OptimumTheme,
  TurbotenantTheme,
  TDSTheme,
  SpectrumTheme,
  WOWTheme,
  WindstreamTheme,
  TXUTheme,
  CenturyLinkTheme,
  CoxTheme,
  DishTheme,
  DishIFramedTheme,
  VerizonTheme,
  FrontierTheme,
  GrandeTheme,
  MediacomTheme,
  RCNTheme,
  WaveTheme,
  QuantumFiberTheme,
  DirecTVTheme,
  AstoundTheme,
];

export * from './ATTTheme';
export * from './AltafiberTheme';
export * from './ApartmentGuideTheme';
export * from './ApartmentListTheme';
export * from './BandwidthBuildersTheme';
export * from './BrightspeedTheme';
export * from './BuckeyeCableTheme';
export * from './CenturyLinkTheme';
export * from './CoxTheme';
export * from './DefaultTheme';
export * from './DirecTVTheme';
export * from './DishIFramedTheme';
export * from './DishTheme';
export * from './EarthlinkTheme';
export * from './Frontier';
export * from './Grande';
export * from './Mediacom';
export * from './OptimumTheme';
export * from './QuantumTheme';
export * from './RCNTheme';
export * from './SpectrumTheme';
export * from './TDSTheme';
export * from './TXUTheme';
export * from './TurbotenantTheme';
export * from './UpdaterTheme';
export * from './VerizonTheme';
export * from './WOWTheme';
export * from './WaveTheme';
export * from './WindstreamTheme';
export * from './XfinityTheme';
export * from './AstoundTheme';
