import { ProviderConfig, ProviderTypes } from 'types';
import { AltafiberTheme } from 'config/themes';

export const CincinnatiBell: ProviderConfig = {
  providerType: ProviderTypes.LEC,
  providerCode: 'cincinnati-bell',
  dcCode: 'Cincinnati Bell',
  name: 'Cincinnati Bell',
  displayName: 'Cincinnati Bell',
  reskinTheme: AltafiberTheme,
  reskinTitle: 'Cincinnati Bell Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'updater_generated',
};
