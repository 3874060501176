import { MicrositeCustomTheme } from 'types';
import { baseTheme } from '../baseTheme';

// ==================================
// BEGIN CUSTOM THEMING FOR MICROSITE
// ==================================

const customTheme: MicrositeCustomTheme = {
  name: 'ApartmentListTheme',
  code: 'apartment-list',
  colors: {
    headerBackground: '#fff',
    headerText: '#212529',
    headerBorder: '#e0e7e9',
    background: '#FFFFFF',
    text: '#000000',
    footerBackground: '#210d36',
    footerText: '#FFFFFF',
    footerBorder: 'transparent',
    progressBar: '#8434d6',
  },
  logoSize: {
    width: '214px',
    height: '40px',
  },
  baseColors: {
    link: '#8434d6',
    cta: '#8434d6',
    hover: '#210d36',
  },
};

// ==================================
// END CUSTOM THEMING FOR MICROSITE
// ==================================

const mergedTheme = Object.assign(baseTheme, {
  colors: {
    ...baseTheme.colors,
    ...customTheme.baseColors,
  },
});

export const ApartmentListTheme = {
  ...mergedTheme,
  customTheme,
};
