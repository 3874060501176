import { MicrositeContext } from 'context';
import { useQual } from 'hooks';
import { useOptionalFieldsById } from 'hooks/useOptionalFieldsById';
import { useCallback, useContext } from 'react';

export type ExtraTrackingDetails = {
  user_uuid?: string;
  property_uuid?: string;
  originator_id?: string;
  promo_id?: string;
  user_email?: string;
};

export const useCreateBaseTrackingEvent = () => {
  const { promoId, uuid, userEmail } = useQual();
  const micrositeConfig = useContext(MicrositeContext);
  const optionalFieldsById = useOptionalFieldsById();

  const addExtraEventDetails = useCallback(
    (event) => {
      const enabledTrackingDetails =
        micrositeConfig?.enabledTrackingDetails ?? [];

      const trackingDetails: ExtraTrackingDetails = {};

      if (enabledTrackingDetails.includes('user_uuid')) {
        trackingDetails.user_uuid = uuid;
      }
      if (enabledTrackingDetails.includes('property_uuid')) {
        trackingDetails.property_uuid = optionalFieldsById?.partnerPropertyId;
      }
      if (enabledTrackingDetails.includes('originator_id')) {
        trackingDetails.originator_id = micrositeConfig?.originatorId;
      }
      if (enabledTrackingDetails.includes('promo_id')) {
        trackingDetails.promo_id = promoId;
      }
      if (enabledTrackingDetails.includes('user_email')) {
        trackingDetails.user_email = userEmail;
      }

      const eventWithTrackingDetails = {
        ...event,
        details: {
          ...event.details,
          ...trackingDetails,
        },
      };

      return eventWithTrackingDetails;
    },
    [
      micrositeConfig?.enabledTrackingDetails,
      micrositeConfig?.originatorId,
      optionalFieldsById?.partnerPropertyId,
      promoId,
      userEmail,
      uuid,
    ]
  );

  return { addExtraEventDetails };
};
