import { MicrositeCustomTheme, MicrositeTheme } from 'types';
import { baseTheme } from '../baseTheme';

// ==================================
// BEGIN CUSTOM THEMING FOR MICROSITE
// ==================================

const customTheme: MicrositeCustomTheme = {
  name: 'SpectrumTheme',
  code: 'spectrum',
  colors: {
    headerBackground: '#00305a',
    headerText: '#fff',
    headerBorder: '#e0e7e9',
    background: '#f9f9f9',
    text: '#000000',
    footerBackground: '#001d37',
    footerText: '#fff',
    footerBorder: 'transparent',
    progressBar: '#3957E1',
  },
  logoSize: {
    width: '123px',
    height: '44px',
  },
  baseColors: {
    link: '#ffb32e',
    cta: '#ffb32e',
    hover: '#ffb32e',
  },
};

// ==================================
// END CUSTOM THEMING FOR MICROSITE
// ==================================

const mergedTheme = Object.assign(baseTheme, {
  colors: {
    ...baseTheme.colors,
    ...customTheme.baseColors,
  },
});

export const SpectrumTheme: MicrositeTheme = {
  ...mergedTheme,
  customTheme,
};
