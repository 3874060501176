import { ProviderConfig, ProviderTypes } from 'types';
import { SpectrumTheme } from 'config/themes';

export const Spectrum: ProviderConfig = {
  providerType: ProviderTypes.MSO,
  providerCode: 'spectrum',
  dcCode: 'Spectrum',
  name: 'Spectrum',
  displayName: 'Spectrum',
  reskinTheme: SpectrumTheme,
  reskinTitle: 'Spectrum Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'iframe',
};
