/**
 * Initial plans page filters/sort/etc values
 */
export const plansPageFormValues = {
  sortBy: 'most_popular',
  internetSpeed: 'internet_speed_95',
  tvChannel: 'tv_channels_10',
  price: 'price_160',
  contractTerms: ['month_12', 'month_24', 'no_contract'],
  hasSpanishChannels: false,
  servicesAvailable: {
    internet: true,
    tv: false,
    phone: false,
    homeSecurity: false,
  },
};
