import { ProviderConfig, ProviderTypes } from 'types';
import { VerizonTheme } from 'config/themes';

export const Verizon: ProviderConfig = {
  providerType: ProviderTypes.LEC,
  providerCode: 'verizon',
  dcCode: 'Verizon',
  name: 'Verizon',
  displayName: 'Verizon',
  reskinTheme: VerizonTheme,
  reskinTitle: 'Verizon Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'linkout_url',
};
