import { useTracking as useReactTracking } from 'react-tracking';
import { TrackEventInput } from '@updater/ui-tracker';
import { TrackEventVerb } from './tracking.types';

/**
 * Custom hook that returns a trackEvent callback that simplifies
 * triggering tracking events.
 *
 * ```
 * const { trackEvent } = useTracking();
 * trackEvent(ClickEvents.PHONE, 'clicked');
 * ```
 *
 * @returns tracker
 */
export function useTracking() {
  const tracker = useReactTracking<TrackEventInput<unknown>>();
  return {
    trackEvent: (
      eventName: string,
      verb: TrackEventVerb,
      details?: Record<string, any>
    ) => {
      tracker.trackEvent({
        eventName,
        verb,
        details,
      });
    },
  };
}
