import { ProviderConfig, ProviderTypes } from 'types';
import { DirecTVTheme } from 'config/themes';

export const DTV: ProviderConfig = {
  providerCode: 'directv_tvi',
  providerType: ProviderTypes.TV,
  dcCode: 'DTV',
  name: 'DTV',
  displayName: 'DirecTV',
  reskinTheme: DirecTVTheme,
  reskinTitle: 'DirecTV Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'none',
};
