import { MicrositeCustomTheme, MicrositeTheme } from 'types';
import { baseTheme } from '../baseTheme';

// ==================================
// BEGIN CUSTOM THEMING FOR MICROSITE
// ==================================

const customTheme: MicrositeCustomTheme = {
  name: 'ApartmentGuideTheme',
  code: 'apartment-guide',
  colors: {
    headerBackground: '#fff',
    headerText: '#212529',
    headerBorder: '#e0e7e9',
    background: '#FFFFFF',
    text: '#000000',
    footerBackground: '#231f20',
    footerText: '#FFFFFF',
    footerBorder: 'transparent',
    progressBar: '#b92128',
  },
  logoSize: {
    width: '282px',
    height: '40px',
  },
  baseColors: {
    link: '#b92128',
    cta: '#b92128',
    hover: '#a31d23',
  },
};

// ==================================
// END CUSTOM THEMING FOR MICROSITE
// ==================================

const mergedTheme = Object.assign(baseTheme, {
  colors: {
    ...baseTheme.colors,
    ...customTheme.baseColors,
  },
});

export const ApartmentGuideTheme: MicrositeTheme = {
  ...mergedTheme,
  customTheme,
};
