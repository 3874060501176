import { ProviderConfig, ProviderTypes } from 'types';
import { BuckeyeCableTheme } from 'config/themes';

export const BuckeyeBroadband: ProviderConfig = {
  providerType: ProviderTypes.LEC,
  providerCode: 'buckeye-broadband',
  dcCode: 'BuckeyeCable',
  name: 'Buckeye Broadband',
  displayName: 'Buckeye Broadband',
  reskinTheme: BuckeyeCableTheme,
  reskinTitle: 'Buckeye Broadband Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'updater_generated',
};
