import { useEffect, useRef } from 'react';
import { useEnvironment } from 'services/environment';
import { init as initDataDog } from './data-dog-rum';

export function Datadog({ children }) {
  const env = useEnvironment();
  const hasInitialized = useRef(false);
  useEffect(() => {
    if (!hasInitialized.current && !window.Cypress) {
      hasInitialized.current = true;
      initDataDog(env);
    }
  }, [env]);
  return <>{children}</>;
}
