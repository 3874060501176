import { MicrositeCustomTheme, MicrositeTheme } from 'types';
import { baseTheme } from '../baseTheme';

// ==================================
// BEGIN CUSTOM THEMING FOR MICROSITE
// ==================================

const customTheme: MicrositeCustomTheme = {
  name: 'CenturyLinkTheme',
  code: 'centurylink',
  colors: {
    headerBackground: '#fff',
    headerText: '#212529',
    headerBorder: '#e0e7e9',
    background: '#FFFFFF',
    text: '#000000',
    footerBackground: '#fff',
    footerText: '#000',
    footerBorder: 'transparent',
    footerGradient:
      'to right, #001e60 0%, #001e60 15%, #0047bb 15%, #0047bb 40%, #48d597 40%',
    progressBar: '#0047bb',
  },
  logoSize: {
    width: '156px',
    height: '48px',
  },
  baseColors: {
    link: '#0047bb',
    cta: '#0047bb',
    hover: '#0047bb',
  },
};

// ==================================
// END CUSTOM THEMING FOR MICROSITE
// ==================================

const mergedTheme = Object.assign(baseTheme, {
  colors: {
    ...baseTheme.colors,
    ...customTheme.baseColors,
  },
});

export const CenturyLinkTheme: MicrositeTheme = {
  ...mergedTheme,
  customTheme,
};
