import { ProviderConfig, ProviderTypes } from 'types';
import { CenturyLinkTheme } from 'config/themes';

export const CenturyLink: ProviderConfig = {
  providerType: ProviderTypes.LEC,
  providerCode: 'centurylink',
  dcCode: 'centurylink',
  name: 'CenturyLink',
  displayName: 'CenturyLink',
  reskinTheme: CenturyLinkTheme,
  reskinTitle: 'CenturyLink Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'iframe',
};
