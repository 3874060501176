import { MicrositeCustomTheme, MicrositeTheme } from 'types';
import { baseTheme } from '../baseTheme';

// ==================================
// BEGIN CUSTOM THEMING FOR MICROSITE
// ==================================

const customTheme: MicrositeCustomTheme = {
  name: 'BandwidthBuildersTheme',
  code: 'bandwidth-builders',
  colors: {
    headerBackground: '#fff',
    headerText: '#212529',
    headerBorder: '#e0e7e9',
    background: '#FFFFFF',
    text: '#000000',
    footerBackground: '#f2f2f2',
    footerText: '#8c8c8c',
    footerBorder: '#e0e7e9',
    progressBar: '#f8a11a',
  },
  logoSize: {
    width: '186px',
    height: '48px',
  },
  baseColors: {
    link: '#f8a11a',
    cta: '#f8a11a',
    hover: '#f19607',
  },
};

// ==================================
// END CUSTOM THEMING FOR MICROSITE
// ==================================

const mergedTheme = Object.assign(baseTheme, {
  colors: {
    ...baseTheme.colors,
    ...customTheme.baseColors,
  },
});

export const BandwidthBuildersTheme: MicrositeTheme = {
  ...mergedTheme,
  customTheme,
};
