import { useEnvironment } from 'services/environment';
import { useContext } from 'react';
import { MicrositeContext } from 'context';

export function useMicrositeGuestBuyflowUrl(): string {
  const { name: envName } = useEnvironment();
  const micrositeConfig = useContext(MicrositeContext);
  if (
    'guestBuyflowUrls' in micrositeConfig &&
    micrositeConfig.guestBuyflowUrls
  ) {
    return micrositeConfig.guestBuyflowUrls[envName.toString()];
  }
  return 'guestBuyflowUrl';
}

export function useProviderUxBuyflowUrl(): string {
  const { name: envName } = useEnvironment();
  const micrositeConfig = useContext(MicrositeContext);
  if ('uxBuyflowUrls' in micrositeConfig && micrositeConfig.uxBuyflowUrls) {
    return micrositeConfig.uxBuyflowUrls[envName.toString()];
  }
  return 'uxBuyflowUrl';
}

export function useUpdaterCrossSellUrl(): string {
  const { name: envName } = useEnvironment();
  let url;
  switch (envName) {
    case 'development':
      url = 'http://bridgevine.io:4200/provider';
      break;
    case 'staging':
    case 'qa':
      url = 'https://staging-cart.updater.com/provider';
      break;
    case 'production':
    default:
      url = 'https://cart.updater.com/provider';
      break;
  }
  return url;
}
