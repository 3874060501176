import { FC, useEffect } from 'react';

import { AuthProvider, useIsAuthenticated, useSignIn } from '@updater/auth';
import { useEnvironment } from '../environment';

export const config = { app: 'mover', name: 'provider-app' } as const;

export const ProviderAuth: FC = ({ children }) => {
  const env = useEnvironment();

  return (
    <AuthProvider url={env.updaterAPIURL} headers={config}>
      <ProviderAuthSession>{children}</ProviderAuthSession>
    </AuthProvider>
  );
};

export const ProviderAuthSession: FC = ({ children }) => {
  const isAuthed = useIsAuthenticated();
  const [_request, signIn] = useSignIn();

  useEffect(() => {
    if (!isAuthed) signIn({ type: 'public', input: {} });
  }, []);

  return <>{children}</>;
};
