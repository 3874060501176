import { DishIFramedTheme } from 'config/themes';
import { ProviderConfig, ProviderTypes } from 'types';

export const MyDish: ProviderConfig = {
  providerType: ProviderTypes.TV,
  providerCode: 'mydish',
  dcCode: 'MyDish',
  name: 'MyDish',
  displayName: 'MyDISH',
  reskinTheme: DishIFramedTheme,
  reskinTitle: 'Get High-Speed Internet | MyDISH',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'none',
};
