import { gql } from '@apollo/client';
import { availableOffers_availableOffers_providerSummaries_provider as providers } from 'types/generated/availableOffers';

export const availableOffers = gql`
  query availableOffers($input: AvailableOffersInput!) {
    availableOffers(input: $input) {
      offers {
        code
        providerCode
        providerOfferId
        name
        contract {
          term {
            length
          }
        }
        disclaimer
        disclaimers {
          disclaimer
          displayLocation
          referenceMark
        }
        proximityDisclaimer {
          proximityDisclosure
          pricingText
        }
        highlights
        extendedHighlights
        promotions {
          description
          disclaimer {
            disclaimer
            displayLocation
            referenceMark
          }
          name
        }
        pricing {
          price
          charges {
            amount
            description
            type
            term {
              unit
              length
            }
          }
          term {
            unit
            length
          }
        }
        tags
        ... on TvInternetOffer {
          products {
            internet {
              name
              downloadSpeed {
                value
                unit
              }
              uploadSpeed {
                value
                unit
              }
              type
              features
            }
            phone {
              name
              type
              features
            }
            tv {
              name
              channels {
                count
              }
              features
              additionalLanguages
              type
            }
          }
        }
        ... on TvInternetLinkoutOffer {
          url
          products {
            internet {
              name
              downloadSpeed {
                value
                unit
              }
              uploadSpeed {
                value
                unit
              }
              type
              features
            }
            phone {
              name
              type
              features
            }
            tv {
              name
              channels {
                count
              }
              features
              additionalLanguages
              type
            }
          }
        }
        metadata {
          fromCache
          cachedAt
          misc
          dummy
        }
      }
      request {
        id
        address {
          street
          unit
          city
          state
          postalCode
        }
      }
      providerSummaries {
        availability
        queryAddress {
          street
          unit
          city
          state
          postalCode
        }
        addressSuggestions {
          street
          unit
          city
          state
          postalCode
        }
        status
        error {
          code
          message
        }
        falconIdentifier
        provider {
          id
          code
          name
          featured
          exclusive
          integrated
          displayName
          url
          linkout
          phoneNumber
          logo {
            baseUrl
            id
            url
          }
          validationSteps
          config {
            supportExistingCustomer
            supportAddressSuggestions
          }
        }
      }
    }
  }
`;

export function filterAvailableProviders(providerSummaries: any): providers[] {
  if (providerSummaries) {
    return providerSummaries
      .filter((x) => x.availability === 'available')
      .map((x) => x.provider);
  }
  return null;
}
