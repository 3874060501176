export enum Line2Type {
  NONE = 'none',
  APARTMENT = 'apartment',
  UNIT = 'unit',
  SUITE = 'suite',
  LOT = 'lot',
  OTHER = 'other',
}

/**
 * The address that is extracted from the querystring and
 * used in the query to serviceability for availableOffers
 */
export interface Address {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  unit?: string;
  line2Type?: Line2Type;
}
