export const stateList = [
  {
    title: 'AK',
    const: 'AK',
    id: 'AK',
    label: 'AK',
    value: 'AK',
  },
  {
    title: 'AL',
    const: 'AL',
    id: 'AL',
    label: 'AL',
    value: 'AL',
  },
  {
    title: 'AR',
    const: 'AR',
    id: 'AR',
    label: 'AR',
    value: 'AR',
  },
  {
    title: 'AZ',
    const: 'AZ',
    id: 'AZ',
    label: 'AZ',
    value: 'AZ',
  },
  {
    title: 'CA',
    const: 'CA',
    id: 'CA',
    label: 'CA',
    value: 'CA',
  },
  {
    title: 'CO',
    const: 'CO',
    id: 'CO',
    label: 'CO',
    value: 'CO',
  },
  {
    title: 'CT',
    const: 'CT',
    id: 'CT',
    label: 'CT',
    value: 'CT',
  },
  {
    title: 'DC',
    const: 'DC',
    id: 'DC',
    label: 'DC',
    value: 'DC',
  },
  {
    title: 'DE',
    const: 'DE',
    id: 'DE',
    label: 'DE',
    value: 'DE',
  },
  {
    title: 'FL',
    const: 'FL',
    id: 'FL',
    label: 'FL',
    value: 'FL',
  },
  {
    title: 'GA',
    const: 'GA',
    id: 'GA',
    label: 'GA',
    value: 'GA',
  },
  {
    title: 'HI',
    const: 'HI',
    id: 'HI',
    label: 'HI',
    value: 'HI',
  },
  {
    title: 'IA',
    const: 'IA',
    id: 'IA',
    label: 'IA',
    value: 'IA',
  },
  {
    title: 'ID',
    const: 'ID',
    id: 'ID',
    label: 'ID',
    value: 'ID',
  },
  {
    title: 'IL',
    const: 'IL',
    id: 'IL',
    label: 'IL',
    value: 'IL',
  },
  {
    title: 'IN',
    const: 'IN',
    id: 'IN',
    label: 'IN',
    value: 'IN',
  },
  {
    title: 'KS',
    const: 'KS',
    id: 'KS',
    label: 'KS',
    value: 'KS',
  },
  {
    title: 'KY',
    const: 'KY',
    id: 'KY',
    label: 'KY',
    value: 'KY',
  },
  {
    title: 'LA',
    const: 'LA',
    id: 'LA',
    label: 'LA',
    value: 'LA',
  },
  {
    title: 'MA',
    const: 'MA',
    id: 'MA',
    label: 'MA',
    value: 'MA',
  },
  {
    title: 'MD',
    const: 'MD',
    id: 'MD',
    label: 'MD',
    value: 'MD',
  },
  {
    title: 'ME',
    const: 'ME',
    id: 'ME',
    label: 'ME',
    value: 'ME',
  },
  {
    title: 'MI',
    const: 'MI',
    id: 'MI',
    label: 'MI',
    value: 'MI',
  },
  {
    title: 'MN',
    const: 'MN',
    id: 'MN',
    label: 'MN',
    value: 'MN',
  },
  {
    title: 'MO',
    const: 'MO',
    id: 'MO',
    label: 'MO',
    value: 'MO',
  },
  {
    title: 'MS',
    const: 'MS',
    id: 'MS',
    label: 'MS',
    value: 'MS',
  },
  {
    title: 'MT',
    const: 'MT',
    id: 'MT',
    label: 'MT',
    value: 'MT',
  },
  {
    title: 'NC',
    const: 'NC',
    id: 'NC',
    label: 'NC',
    value: 'NC',
  },
  {
    title: 'ND',
    const: 'ND',
    id: 'ND',
    label: 'ND',
    value: 'ND',
  },
  {
    title: 'NE',
    const: 'NE',
    id: 'NE',
    label: 'NE',
    value: 'NE',
  },
  {
    title: 'NH',
    const: 'NH',
    id: 'NH',
    label: 'NH',
    value: 'NH',
  },
  {
    title: 'NJ',
    const: 'NJ',
    id: 'NJ',
    label: 'NJ',
    value: 'NJ',
  },
  {
    title: 'NM',
    const: 'NM',
    id: 'NM',
    label: 'NM',
    value: 'NM',
  },
  {
    title: 'NV',
    const: 'NV',
    id: 'NV',
    label: 'NV',
    value: 'NV',
  },
  {
    title: 'NY',
    const: 'NY',
    id: 'NY',
    label: 'NY',
    value: 'NY',
  },
  {
    title: 'OH',
    const: 'OH',
    id: 'OH',
    label: 'OH',
    value: 'OH',
  },
  {
    title: 'OK',
    const: 'OK',
    id: 'OK',
    label: 'OK',
    value: 'OK',
  },
  {
    title: 'OR',
    const: 'OR',
    id: 'OR',
    label: 'OR',
    value: 'OR',
  },
  {
    title: 'PA',
    const: 'PA',
    id: 'PA',
    label: 'PA',
    value: 'PA',
  },
  {
    title: 'RI',
    const: 'RI',
    id: 'RI',
    label: 'RI',
    value: 'RI',
  },
  {
    title: 'SC',
    const: 'SC',
    id: 'SC',
    label: 'SC',
    value: 'SC',
  },
  {
    title: 'SD',
    const: 'SD',
    id: 'SD',
    label: 'SD',
    value: 'SD',
  },
  {
    title: 'TN',
    const: 'TN',
    id: 'TN',
    label: 'TN',
    value: 'TN',
  },
  {
    title: 'TX',
    const: 'TX',
    id: 'TX',
    label: 'TX',
    value: 'TX',
  },
  {
    title: 'UT',
    const: 'UT',
    id: 'UT',
    label: 'UT',
    value: 'UT',
  },
  {
    title: 'VA',
    const: 'VA',
    id: 'VA',
    label: 'VA',
    value: 'VA',
  },
  {
    title: 'VT',
    const: 'VT',
    id: 'VT',
    label: 'VT',
    value: 'VT',
  },
  {
    title: 'WA',
    const: 'WA',
    id: 'WA',
    label: 'WA',
    value: 'WA',
  },
  {
    title: 'WI',
    const: 'WI',
    id: 'WI',
    label: 'WI',
    value: 'WI',
  },
  {
    title: 'WV',
    const: 'WV',
    id: 'WV',
    label: 'WV',
    value: 'WV',
  },
  {
    title: 'WY',
    const: 'WY',
    id: 'WY',
    label: 'WY',
    value: 'WY',
  },
];

interface State {
  title: string;
  const: string;
}

export const centralStateList: Array<State> = [
  { title: 'AL', const: 'AL' },
  { title: 'AR', const: 'AR' },
  { title: 'FL', const: 'FL' },
  { title: 'GA', const: 'GA' },
  { title: 'IL', const: 'IL' },
  { title: 'IN', const: 'IN' },
  { title: 'KY', const: 'KY' },
  { title: 'LA', const: 'LA' },
  { title: 'MI', const: 'MI' },
  { title: 'MS', const: 'MS' },
  { title: 'SC', const: 'SC' },
  { title: 'TN', const: 'TN' },
  { title: 'IN', const: 'IN' },
];

export const isCentralState = (state: string) =>
  Boolean(
    centralStateList.filter((central: State) => central.const === state).length
  );
