/*
// This is a sample qual that Shop App sends into the guest-buyflow app
// We should have most of this data from the DC API offer.
// Some of it is not needed
{
  "initialQual": false,
  "initialQualData": {
    "phoneNumber": "999-992-1223",
    "promoId": "5002236",
    "street": "510 W 26TH ST",
    "apartment": "APT 102",
    "city": "",
    "state": "",
    "zip": "23225",
    "theme": "default",
    "buyflowType": "multi",
    "flowType": "offers-v2",
    "waitPageType": "funfacts",
    "filterOutProviders": [],
    "comcastAffiliateLinkout": false,
    "comcastTransfer": false,
    "existingCustomerCallIn": false,
    "newInternetSpeedInstruction": false,
    "upsell": false,
    "isExpress": false,
    "upsellTV": false,
    "upsellGig": false,
    "reviewOrder": false,
    "disableAbandonedCart": false,
    "hideTFN": false,
    "apiMock": false,
    "genericCallToOrder": false,
    "prodTest": false,
    "preferredProvider": false,
    "preventRedirect": false,
    "multipleOffers": false,
    "hasFailureLimit": false,
    "filterRevamp": false,
    "removeDuplicatedOffers": false,
    "SCORESHOP": false,
    "newBuyflow": false,
    "comcastAutoTransfer": false,
    "defaultShortestContractOffers": false,
    "failureLimit": 0,
    "offerCode": "",
    "addOfferCode": false,
    "simulation": {
      "providers": []
    },
    "smsOptIn": false,
    "defaultSort": ""
  },
  "qualification": {
    "phoneNumber": "999-992-1223",
    "promoId": "5002236",
    "street": "510 W 26TH ST",
    "apartment": "APT 102",
    "city": "",
    "state": "",
    "zip": "23225",
    "theme": "default",
    "buyflowType": "multi",
    "flowType": "offers-v2",
    "waitPageType": "funfacts",
    "filterOutProviders": [],
    "comcastAffiliateLinkout": false,
    "comcastTransfer": false,
    "existingCustomerCallIn": false,
    "newInternetSpeedInstruction": false,
    "upsell": false,
    "isExpress": false,
    "upsellTV": false,
    "upsellGig": false,
    "reviewOrder": false,
    "disableAbandonedCart": false,
    "hideTFN": false,
    "apiMock": false,
    "genericCallToOrder": false,
    "prodTest": false,
    "preferredProvider": false,
    "preventRedirect": false,
    "multipleOffers": false,
    "hasFailureLimit": false,
    "filterRevamp": false,
    "removeDuplicatedOffers": false,
    "SCORESHOP": false,
    "newBuyflow": false,
    "comcastAutoTransfer": false,
    "defaultShortestContractOffers": false,
    "failureLimit": 0,
    "offerCode": "",
    "addOfferCode": false,
    "simulation": {
      "providers": []
    },
    "smsOptIn": false,
    "defaultSort": "",
    "isGeneric": false,
    "optionalFields": {
      "option1": "41fa6cf1-e0e2-429a-9a73-a98110b72b94"
    }
  },
  "uniqueIframeSessionId": "L4FU4XQ4",
  "uuid": "41fa6cf1-e0e2-429a-9a73-a98110b72b94",
  "minConfidenceUsed": true,
  "dcSessionId": "b1683067-6db3-11ed-8a7b-0242ac120003",
  "dcFwsTid": "1200024519",
  "providerSummary": [
    {
      "provider": "Comcast",
      "offers": 21
    },
    {
      "provider": "DTV",
      "offers": 4
    }
  ],
  "hasOffers": true,
  "isAptSuiteRequired": false,
  "dcTransactionId": "cd8a4868-c4bd-4f48-a002-b201ab71e667",
  "cart": {
    "extendedHighlights": [],
    "hasHomeSecurity": false,
    "hasInternet": true,
    "hasInternetHighlight": true,
    "hasLatino": false,
    "hasPhone": false,
    "hasTV": false,
    "highlights": [
      "Rate applies for first 24 months",
      "Download Speed up to 400 Mbps"
    ],
    "id": 4059806902,
    "internetDownloadSpeed": "Mbps",
    "internetDownloadSpeedValue": 400,
    "longTermPrice": 97,
    "longTermPriceUnit": "Month",
    "name": "XI Fast SP w/Agrmt",
    "offerCode": "904a5dc9-ef7e-4eec-a5d6-cf5f33c47e64",
    "promotions": [],
    "provider": "Comcast",
    "providerCode": "9626150208",
    "shortTermPrice": 35,
    "shortTermPriceUnit": "Month",
    "shortTermPriceValue": 24,
    "starRanking": 0,
    "tvChannelLineup": []
  },
  "address": {
    "street": "510 W 26TH ST",
    "apartment": "102",
    "lineTwo": "",
    "city": "RICHMOND",
    "state": "VA",
    "zip": "23225",
    "zipFour": "3830"
  },
  "sessionId": "LAY8I7CS"
}
*/

export function generateAsyncQual({
  asyncConfig,
  source,
  customerInfo,
  qual,
  chosenOffer,
  uuid,
  requestId,
  optionalFieldsById,
  originatorId,
  enabledTrackingDetails,
}) {
  let asyncQual = {
    asyncConfig: {},
    initialQual: false,
    initialQualData: {},
    qualification: {},
    uniqueIframeSessionId: '',
    uuid: '',
    minConfidenceUsed: true,
    requestId,
    providerSummary: [],
    hasOffers: true,
    isAptSuiteRequired: false,
    cart: {},
    address: {},
    customerInfo: {},
    originatorId: '',
    enabledTrackingDetails: [],
  };

  asyncQual = Object.assign(asyncQual, {
    initialQualData: qual,
    qualification: {
      ...qual,
      optionalFieldsById,
      originatorId,
      enabledTrackingDetails,
    },
  });
  asyncQual = Object.assign(asyncQual, {
    uuid,
  });
  asyncQual = Object.assign(asyncQual, {
    address: {
      street: qual.street,
      apartment: qual.apartment,
      city: qual.city,
      state: qual.state,
      lineTwo: qual.line2Type,
      zip: qual.zip.substr(0, 5),
      zipFour: '',
    },
  });
  asyncQual = Object.assign(asyncQual, {
    customerInfo,
  });

  /*
  // TODO: we're missing the following fields:
  //   hasInternetHighlight
  //   hasLatino
  //   starRanking
  //   tvChannelLineup
  // Unclear if these are needed; we are able to checkout async
  // without this data...
  // Also TODO: if we need DC API long-term to process async
  // checkouts, we need to centralize a s12y => DC offer type
  // conversion in frontend-core, and we need to standardize
  // what fields we request from s12y so they can produce the
  // offer in DC's format (right now we're mixing and matching
  // which is not ideal...)
  */
  const longTermPricing = chosenOffer?.pricing?.charges?.filter(
    (charge: { type: string }) => charge.type === 'long_term'
  )?.[0];
  const shortTermPricing = chosenOffer?.pricing?.charges?.filter(
    (charge: { type: string }) => charge.type === 'short_term'
  )?.[0];
  asyncQual = Object.assign(asyncQual, {
    asyncConfig: {
      ...asyncConfig,
      source,
    },
    cart: {
      extendedHighlights: chosenOffer?.extendedHighlights,
      hasHomeSecurity: false,
      hasInternet: !!chosenOffer?.products.internet,
      hasPhone: !!chosenOffer?.products.phone,
      hasTV: !!chosenOffer?.products.tv,
      highlights: chosenOffer?.highlights,
      id: chosenOffer?.code,
      internetDownloadSpeed:
        chosenOffer.products?.internet?.downloadSpeed?.unit,
      internetDownloadSpeedValue:
        chosenOffer.products?.internet?.downloadSpeed?.value,
      longTermPrice: longTermPricing?.amount,
      longTermPriceUnit: longTermPricing?.term?.unit,
      name: chosenOffer?.name,
      pricing: chosenOffer?.pricing,
      products: chosenOffer?.products,
      offerCode: chosenOffer?.code,
      providerCode: chosenOffer?.providerCode,
      providerOfferId: chosenOffer?.providerOfferId,
      promotions: chosenOffer.promotions || [],
      provider: chosenOffer?.providerCode,
      shortTermPrice: shortTermPricing?.amount,
      shortTermPriceUnit: shortTermPricing?.term?.unit,
      shortTermPriceValue: shortTermPricing?.term?.length,
      broadbandNutritionLabelData: chosenOffer?.broadbandNutritionLabelData,
    },
  });

  return asyncQual;
}
