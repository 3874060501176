import { useQual } from 'hooks';
import { Address } from 'types';

// Note: as long as we have this "qual" object, we should
// keep the address split between its individual parts.
// Because we need to mutate this qual, and because it also
// gets stored in localStorage cache for the lifetime of the session,
// we're going to need to translate it back and forth *somewhere*.
// Strong opinion: we should keep the qual object "clean" so it's
// a single source of truth.  Otherwise we're going to expose
// ourselves to state issues when the address in the qual object
// deviates from the address object somewhere else in memory or
// localStorage.
//
// Note that we also have the painful apartment-unit and
// zip-postalCode discrepancies to deal with.
export function useAddress(): Address {
  const qualData = useQual();
  const { street, unit, city, state, zip, line2Type } = {
    ...qualData,
  };

  const address = {
    street,
    unit,
    city,
    state,
    postalCode: zip,
    line2Type,
  } as Address;
  return address;
}
