import { ProviderConfig, ProviderTypes } from 'types';
import { ATTTheme } from 'config/themes';

export const ATT: ProviderConfig = {
  providerType: ProviderTypes.LEC,
  providerCode: 'att',
  dcCode: 'att',
  name: 'ATT',
  displayName: 'AT&T',
  reskinTheme: ATTTheme,
  reskinTitle: 'AT&T Authorized Dealer',
  showPricingDisclaimer: true,
  broadbandDisplayType: 'linkout_url',
};
