import 'bootstrap/dist/css/bootstrap.min.css';
import { WorkSansLoader } from 'components/WorkSansLoader/WorkSansLoader';
import { AppProps } from 'next/app';
import {
  ApolloClient,
  Datadog,
  Environment,
  ErrorBoundary,
  GlobalStyles,
  MockServiceWorker,
} from 'services';
import { ProviderAuth, persistAuth } from 'services/auth';
import { FullstoryProvider } from 'services/fullstory/fullstoryProvider';
import { GrowthbookWrapper } from 'services/growthbook/GrowthbookWrapper';

persistAuth();

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <WorkSansLoader />
      <ErrorBoundary>
        <GlobalStyles />
        <Environment>
          <GrowthbookWrapper>
            <Datadog>
              <FullstoryProvider />
              <MockServiceWorker>
                <ProviderAuth>
                  <ApolloClient>
                    <Component {...pageProps} />
                  </ApolloClient>
                </ProviderAuth>
              </MockServiceWorker>
            </Datadog>
          </GrowthbookWrapper>
        </Environment>
      </ErrorBoundary>
    </>
  );
}
