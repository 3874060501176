import { ProviderConfig, ProviderTypes } from 'types';
import { MediacomTheme } from 'config/themes';

export const Mediacom: ProviderConfig = {
  providerType: ProviderTypes.MSO,
  providerCode: 'mediacom',
  dcCode: 'Mediacom',
  name: 'Mediacom',
  displayName: 'Mediacom',
  reskinTheme: MediacomTheme,
  reskinTitle: 'Mediacom Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'linkout_url',
};
