import Head from 'next/head';
import { useQual } from 'hooks';
import { useContext } from 'react';
import { MicrositeContext } from 'context';

export function GoogleTagManager() {
  const { gtmCode: gtmCodeQual } = useQual();
  const { gtmCode: gtmCodeConfig } = useContext(MicrositeContext);

  // Prioritize the GTM code from qual over the one from the microsite config
  const gtmCode = gtmCodeQual || gtmCodeConfig;

  if (!gtmCode) {
    return null;
  }

  return (
    <>
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer',"${gtmCode}");`,
          }}
        />
      </Head>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmCode}"
                        height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />
    </>
  );
}
