import { ProviderConfig, ProviderTypes } from 'types';
import { BrightspeedTheme } from 'config/themes';

export const Brightspeed: ProviderConfig = {
  providerType: ProviderTypes.LEC,
  providerCode: 'brightspeed',
  dcCode: 'Brightspeed',
  name: 'Brightspeed',
  displayName: 'Brightspeed',
  reskinTheme: BrightspeedTheme,
  reskinTitle: 'Brightspeed Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'updater_generated',
};
