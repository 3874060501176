import { MicrositeCustomTheme, MicrositeTheme } from 'types';
import { baseTheme } from '../baseTheme';

// ==================================
// BEGIN CUSTOM THEMING FOR MICROSITE
// ==================================

const customTheme: MicrositeCustomTheme = {
  name: 'ATTTheme',
  code: 'ATT',
  colors: {
    headerBackground: '#fff',
    headerText: '#212529',
    headerBorder: '#e0e7e9',
    background: '#FFFFFF',
    text: '#000000',
    footerBackground: '#000000',
    footerText: '#FFFFFF',
    footerBorder: '#000000',
    progressBar: '#007BFF',
  },
  logoSize: {
    width: '120px',
    height: '52px',
  },
  baseColors: {
    link: '#007BFF',
    hover: '#0069D9',
    cta: '#007BFF',
  },
};

// ==================================
// END CUSTOM THEMING FOR MICROSITE
// ==================================

const mergedTheme = Object.assign(baseTheme, {
  colors: {
    ...baseTheme.colors,
    ...customTheme.baseColors,
  },
});

export const ATTTheme: MicrositeTheme = {
  ...mergedTheme,
  customTheme,
};
