import { Flex, H1, Image, P } from '@updater/ui-uds';

export function ErrorFallback({ error }) {
  console.warn(error.message);
  return (
    <Flex
      mt="xxxxl"
      width={['100%', null, '50%', null, null]}
      alignSelf="center"
      flexDirection="column"
      alignContent="center"
    >
      <Image
        mt="xxxxl"
        src="https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto/v1/404%20error/Updater_Toolbox_SVG"
        width="70%"
        mx="auto"
        alt="vector drawing of an open toolbox"
      />
      <H1 textAlign="center" variant="xlBold" mb="s" mt="m">
        Oops! Looks like we're having some technical difficulties
      </H1>
      <P textAlign="center" variant="l">
        We're putting up walls and painting them with fresh code and such.
        <br /> We'll get this cleaned up in no time.
      </P>
    </Flex>
  );
}
