/* eslint @typescript-eslint/no-use-before-define: 0 */
import * as Yup from 'yup';
import { useContext, useMemo } from 'react';
import { Address } from 'types';
import { OffersContext } from 'context';
import { useAddress } from './useAddress';

/**
 * Pulls address data from availableOffers and returns:
 * * addressUnavailable boolean (flag indicating availability)
 * * addressSuggestions list (list of address suggestions)
 * @returns availability
 */
export function useAddressSuggestions(): {
  loading: boolean;
  addressValid: boolean;
  addressUnavailable: boolean;
  addressSuggestions: Array<Address>;
} {
  const { primaryData } = useContext(OffersContext);
  const { loading, providerSummaries } = primaryData;
  const address = useAddress();

  const offerAddress = useMemo(() => {
    let offerAddress = {
      loading,
      addressValid: validAddress(address),
      addressUnavailable: false,
      addressSuggestions: [],
    };

    if (!loading) {
      const isOfferAvailable = providerSummaries?.some(
        (summary) => summary.availability === 'available'
      );

      if (isOfferAvailable) {
        return offerAddress;
      }

      const firstProviderSummary =
        providerSummaries?.length > 0 ? providerSummaries[0] : undefined;

      if (firstProviderSummary) {
        offerAddress = Object.assign(offerAddress, {
          addressUnavailable:
            firstProviderSummary.availability === 'unavailable',
          addressSuggestions: firstProviderSummary.addressSuggestions,
        });
      }
    }
    return offerAddress;
  }, [address, loading, providerSummaries]);

  return offerAddress;
}

/**
 * Function that checks/validates an object to see if it looks like a
 * valid address
 * @param address
 * @returns boolean
 */
function validAddress(address: Address) {
  try {
    addressSchema.validateSync(address);
  } catch (error) {
    return false;
  }
  return true;
}

const addressSchema = Yup.object({
  street: Yup.string().required(),
  city: Yup.string().required(),
  state: Yup.string().required(),
  postalCode: Yup.string().required(),
});
