import { ProviderConfig, ProviderTypes } from 'types';
import { EarthlinkTheme } from 'config/themes';

export const Earthlink: ProviderConfig = {
  providerType: ProviderTypes.LEC,
  providerCode: 'earthlink',
  dcCode: 'Earthlink',
  name: 'Earthlink',
  displayName: 'Earthlink',
  reskinTheme: EarthlinkTheme,
  reskinTitle: 'Earthlink Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'updater_generated',
};
