import { ProviderConfig } from 'types';
import { UseOffersResult } from '@updater/ui-widgets';
import { ProviderConfigList } from '.';

export function getTopCrossSellProvider(
  crossSellProviders: ProviderConfig[],
  availableProviders: UseOffersResult['availableProviders']
) {
  if (!availableProviders) return undefined;

  const availableProviderCodes = availableProviders?.map(({ code }) => code);

  const sortedProviders = crossSellProviders?.filter((p) =>
    availableProviderCodes.includes(p.providerCode)
  );

  const topCrossSellProvider = sortedProviders?.length
    ? sortedProviders[0]
    : undefined;

  return topCrossSellProvider;
}

export function getProviderLogoFromS12y(
  provider: ProviderConfig,
  availableProviders: UseOffersResult['availableProviders'] | null
) {
  if (availableProviders === null) {
    return undefined;
  }

  const s12yProvider =
    availableProviders?.length &&
    availableProviders.find((s12yp) => {
      return s12yp.code === provider.providerCode;
    });
  if (s12yProvider) {
    return s12yProvider.logo;
  }
  return undefined;
}

export type ShowPricingDescriptionArgs = {
  providerCode: string;
  pricingDescription?: string;
};

/**
 * Treated as whitelist.
 */
export const showPricingDescription = ({
  providerCode,
  pricingDescription,
}: ShowPricingDescriptionArgs) => {
  if (Boolean(pricingDescription)) {
    return false;
  }

  const providerConfig = ProviderConfigList.find(
    (provider) => provider?.displayName === providerCode
  );

  if (!providerConfig) return false;

  return providerConfig.showPricingDisclaimer;
};

/**
 * Retrieves the provider configuration based on the provider code.
 *
 * @param {string} providerCode - The code of the provider to retrieve configuration for.
 * @return {ProviderConfig | null} The provider configuration if found, otherwise null.
 */
export const getProviderConfig = ({
  providerCode,
}: {
  providerCode: string;
}) => {
  const providerConfig = ProviderConfigList.find(
    (provider) => provider?.providerCode === providerCode
  );

  if (!providerConfig) return null;

  return providerConfig;
};
