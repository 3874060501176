import { ProviderConfig, ProviderTypes } from 'types';
import { DirecTVTheme } from 'config/themes';

export const ATTDTV: ProviderConfig = {
  providerType: ProviderTypes.SYNTHETIC_BUNDLE,
  providerCode: 'directv_att',
  dcCode: 'ATT.DTV',
  name: 'ATT.DTV',
  displayName: 'DirecTV',
  reskinTheme: DirecTVTheme,
  reskinTitle: 'DirecTV Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'linkout_url',
};
