import { EnvironmentVariables } from 'services/environment';
import { datadogRum } from '@datadog/browser-rum';
import { datadogConfig } from 'config';

export const init = (env: EnvironmentVariables) => {
  datadogRum.init({
    applicationId: datadogConfig.APPLICATION_ID,
    clientToken: datadogConfig.CLIENT_TOKEN,
    defaultPrivacyLevel: 'mask-user-input',
    site: 'datadoghq.com',
    service: 'provider-app',
    proxyUrl: 'https://dd-rum-proxy.updater.com/',
    version: global.__VERSION_STRING__, // eslint-disable-line
    env: env.name,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: [/https.*api.*updater.com.*/],
    beforeSend: (event, context) => {
      if (event.type === 'resource') {
        let parsedBody;
        try {
          parsedBody = JSON.parse((context as any).requestInit?.body);
        } catch (e) {
          parsedBody = {};
        }
        if (
          event.resource.url.endsWith('/graphql') &&
          event.resource.method === 'POST'
        ) {
          // eslint-disable-next-line no-param-reassign
          event.context = {
            ...event.context,
            graphQLOperationName: parsedBody.operationName,
            graphQLOperationType: parsedBody.query?.match(/^([\w]+)\s/)[1],
          };
        }
      }

      if (typeof window !== 'undefined' && window.FS) {
        const fsSessionUrl = window?.FS?.getCurrentSessionURL?.();
        event.context!.fullStoryUrl = fsSessionUrl; // eslint-disable-line
      }

      return true;
    },
  });
  datadogRum.startSessionReplayRecording();
};
