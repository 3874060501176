import { Work_Sans } from 'next/font/google';

const WorkSans = Work_Sans({
  subsets: ['latin', 'latin-ext'],
  weight: ['300', '400', '600'],
  display: 'swap',
  variable: '--font-work-sans',
});

export const WorkSansLoader = () => {
  return (
    <style jsx global>{`
      :root {
        --font-work-sans: ${WorkSans.style.fontFamily};
      }
    `}</style>
  );
};
