import { ProviderConfig, ProviderTypes } from 'types';
import { GrandeTheme } from 'config/themes';

export const Grande: ProviderConfig = {
  providerType: ProviderTypes.MSO,
  providerCode: 'grande-communications',
  dcCode: 'Grande',
  name: 'Grande',
  displayName: 'Grande',
  reskinTheme: GrandeTheme,
  reskinTitle: 'Grande Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'none',
};
