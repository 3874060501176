import { ProviderConfig, ProviderTypes } from 'types';
import { WOWTheme } from 'config/themes';

export const WOW: ProviderConfig = {
  providerType: ProviderTypes.MSO,
  providerCode: 'wow',
  dcCode: 'WOW!',
  name: 'WOW',
  displayName: 'WOW',
  reskinTheme: WOWTheme,
  reskinTitle: 'WOW! Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'iframe',
};
