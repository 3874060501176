import { AvailableOffersInput as GqlAvailableOffersInput } from 'types/generated/globalTypes';
import {
  availableOffers,
  availableOffers_availableOffers_offers,
} from 'types/generated/availableOffers';

export * from './availableOffers';
export * from './dcSession';
export * from './normalizedAddress';
export type AvailableOffersRequest = { input: GqlAvailableOffersInput };
export type AvailableOffersResponse = availableOffers;
export type Offer = availableOffers_availableOffers_offers;
