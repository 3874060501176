import { css, Global, useTheme } from '@emotion/react';
import { Theme } from '@updater/ui-uds';

const styles = (theme: Theme) => css`
  html,
  body {
    padding: 0;
    margin: 0;
    height: 100%;
    font-family: var(--font-work-sans, 'Work Sans');
    font-size: 16px;
  }

  body {
    overflow-y: scroll;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  #__next {
    height: 100%;
  }

  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  span,
  b,
  small {
    font-family: var(--font-work-sans, 'Work Sans'), sans-serif;
  }
`;

export function GlobalStyles() {
  const theme = useTheme();
  return <Global styles={styles(theme)} />;
}
