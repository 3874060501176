import { ProviderConfig, ProviderTypes } from 'types';
import { BrightspeedTheme } from 'config/themes';

// Origin - https://updater.atlassian.net/browse/TVI-1204 - two Brightspeed providers to support both API integrations
export const BrightspeedV2: ProviderConfig = {
  providerType: ProviderTypes.LEC,
  providerCode: 'brightspeedv2',
  dcCode: 'brightspeedv2',
  name: 'BrightspeedV2',
  displayName: 'Brightspeed',
  reskinTheme: BrightspeedTheme,
  reskinTitle: 'Brightspeed Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'updater_generated',
};
