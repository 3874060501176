import { ProviderConfig, ProviderTypes } from 'types';
import { WindstreamTheme } from 'config/themes';

export const Windstream: ProviderConfig = {
  providerType: ProviderTypes.LEC,
  providerCode: 'windstream',
  dcCode: 'Windstream',
  name: 'Windstream',
  displayName: 'Windstream',
  reskinTheme: WindstreamTheme,
  reskinTitle: 'Windstream Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'linkout_url',
};
