import { MicrositeCustomTheme, MicrositeTheme } from 'types';
import { baseTheme } from '../baseTheme';

// ==================================
// BEGIN CUSTOM THEMING FOR MICROSITE
// ==================================

const customTheme: MicrositeCustomTheme = {
  name: 'OptimumTheme',
  code: 'optimum',
  colors: {
    headerBackground: '#000000',
    headerText: '#FFFFFF',
    headerBorder: '#000000',
    background: '#f9f9f9',
    text: '#000000',
    footerBackground: '#f2f2f2',
    footerText: '#8c8c8c',
    footerBorder: '#e0e7e9',
    progressBar: '#000',
  },
  logoSize: {
    width: '168px',
    height: '48px',
  },
  baseColors: {
    link: '#0084d6',
    cta: '#0084d6',
    hover: '#0074bd',
  },
};

// ==================================
// END CUSTOM THEMING FOR MICROSITE
// ==================================

const mergedTheme = Object.assign(baseTheme, {
  colors: {
    ...baseTheme.colors,
    ...customTheme.baseColors,
  },
});

export const OptimumTheme: MicrositeTheme = {
  ...mergedTheme,
  customTheme,
};
