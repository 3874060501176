import { ProviderConfig, ProviderTypes } from 'types';
import { CoxTheme } from 'config/themes';

export const Cox: ProviderConfig = {
  providerType: ProviderTypes.MSO,
  providerCode: 'cox',
  dcCode: 'Cox',
  name: 'Cox',
  displayName: 'Cox',
  reskinTheme: CoxTheme,
  reskinTitle: 'Cox Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'linkout_url',
};
