import Cookies from 'js-cookie';
import type { AuthSession } from '@updater/auth';

type Session = AuthSession & { allowIFrame?: boolean };

type Settings = {
  expires: Date;
  SameSite?: string;
  Secure?: boolean;
  Partitioned?: boolean;
};

export const SessionCookie = {
  read() {
    try {
      const { accessToken, ...session } = JSON.parse(Cookies.get('session'));
      return {
        'access-token': accessToken,
        ...session,
      };
    } catch (e) {}
  },

  write(session?: Session, allowIFrame = false) {
    if (session) {
      const settings: Settings = {
        expires: new Date(new Date().getTime() + 45 * 60 * 1000),
      };
      if (allowIFrame && !session.allowIFrame) {
        settings.SameSite = 'None';
        settings.Secure = true;
        settings.Partitioned = true;
        Cookies.remove('session');
      }
      Cookies.set(
        'session',
        JSON.stringify({ ...session, allowIFrame }),
        settings
      );
    } else Cookies.remove('session');
  },
};
