import getConfig from 'next/config';
import { EnvironmentContext } from './EnvironmentContext';
import { EnvironmentVariables } from './types';

export function Environment({ children }) {
  const { publicRuntimeConfig } = getConfig();
  const env: EnvironmentVariables = {
    ...publicRuntimeConfig,
    apiMocking: publicRuntimeConfig.apiMocking === 'enabled',
    inProduction: publicRuntimeConfig.name === 'prod',
    crossSellsUrl:
      publicRuntimeConfig.name === 'prod'
        ? 'https://cart.xfinityspecial.com'
        : 'https://buyflow.qa.bridgevine.io',
  };
  return (
    <EnvironmentContext.Provider value={env}>
      {children}
    </EnvironmentContext.Provider>
  );
}
