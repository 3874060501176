import { DishIFramedTheme } from 'config/themes';
import { ProviderConfig, ProviderTypes } from 'types';

export const Dish: ProviderConfig = {
  providerType: ProviderTypes.TV,
  providerCode: 'dish',
  dcCode: 'Dish',
  name: 'dish',
  displayName: 'DISH',
  reskinTheme: DishIFramedTheme,
  reskinTitle: 'DISH Shop For Internet',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'none',
};
