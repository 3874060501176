import { store } from '@updater/auth';
import { SessionCookie } from './session-cookie';

// use cookie based persistence of session
export function persistAuth() {
  if (typeof window === 'undefined') return;
  const urlParams = window?.location?.search;
  const queryString = new URLSearchParams(urlParams);
  const allowIFrame = queryString.get('iFrameMode') === '1';
  const session = SessionCookie.read();
  if (session) {
    store.setSession({ mode: 'anonymous', session });
  }

  // sync changes to local cache
  store.subscribe(({ session }) => SessionCookie.write(session, allowIFrame));
}
