import { MicrositeCustomTheme, MicrositeTheme } from 'types';
import { baseTheme } from '../baseTheme';

// ==================================
// BEGIN CUSTOM THEMING FOR MICROSITE
// ==================================

const customTheme: MicrositeCustomTheme = {
  name: 'WaveTheme',
  code: 'wave',
  colors: {
    headerBackground: '#F9F9F9',
    headerText: '#222222',
    headerBorder: '#E5E5E5',
    background: '#FFFFFF',
    text: '#000000',
    footerBackground: '#F9F9F9',
    footerText: '#222222',
    footerBorder: '#E5E5E5',
    progressBar: '#3957E1',
  },
  logoSize: {
    width: '168px',
    height: '75px',
  },
  baseColors: {
    link: '#3957E1',
    cta: '#3957E1',
    hover: '#2F48BB',
  },
};

// ==================================
// END CUSTOM THEMING FOR MICROSITE
// ==================================

const mergedTheme = Object.assign(baseTheme, {
  colors: {
    ...baseTheme.colors,
    ...customTheme.baseColors,
  },
});

export const WaveTheme: MicrositeTheme = {
  ...mergedTheme,
  customTheme,
};
