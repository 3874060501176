import { useContext } from 'react';
import { useQual, useStore, useMicrositeGuestBuyflowUrl } from 'hooks';
import { MicrositeContext, OffersContext } from 'context';
import { generateAsyncQual } from 'services';
import { SiteMode } from 'types';
import { useShallow } from 'zustand/react/shallow';
import { useOptionalFieldsById } from './useOptionalFieldsById';

export function useAsyncCheckoutUrl(): string | undefined {
  const {
    name: source,
    asyncConfig,
    gtmCode,
    originatorId,
    enabledTrackingDetails,
  } = useContext(MicrositeContext);
  const qual = useQual();
  const { uuid, userFirstName, userLastName, userEmail, userPhoneNumber } =
    qual;
  const optionalFieldsById = useOptionalFieldsById();
  const guestBuyflowUrl = useMicrositeGuestBuyflowUrl();
  const offersContext = useContext(OffersContext);
  const { primaryData, secondaryData } = offersContext;
  const [asyncChosenOffer] = useStore(
    useShallow((store) => [store.asyncChosenOffer])
  );
  const [siteMode] = useStore((store) => [store.siteMode]);
  const requestId =
    siteMode === SiteMode.DEFAULT_MODE
      ? primaryData.requestId
      : secondaryData.requestId;
  try {
    if (asyncChosenOffer === undefined) {
      throw new Error('asyncChosenOffer undefined');
    }
    if (!qual) {
      throw new Error('no qual');
    }
    if (!uuid) {
      throw new Error('no uuid');
    }

    if (!qual.gtmCode && gtmCode) qual.gtmCode = gtmCode;

    // eslint-disable-next-line no-console
    console.log('[useAsyncCheckoutUrl] asyncChosenOffer', asyncChosenOffer);

    const payload = generateAsyncQual({
      asyncConfig,
      optionalFieldsById,
      source,
      customerInfo: {
        firstName: userFirstName,
        lastName: userLastName,
        email: userEmail,
        phoneNumber: userPhoneNumber,
      },
      qual,
      chosenOffer: asyncChosenOffer,
      uuid,
      requestId,
      originatorId,
      enabledTrackingDetails,
    });
    const encoded = btoa(unescape(encodeURIComponent(JSON.stringify(payload))));
    const url = `${guestBuyflowUrl}?uuid=${uuid}&qual=${encoded}`;
    // eslint-disable-next-line no-console
    console.log(url);
    // We're sending users to a differet app here; we can't use next router.
    return url;
  } catch (err) {
    console.log(`[useAsyncCheckoutUrl] ${err.message}`);
  }
  return undefined;
}
