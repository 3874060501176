import { gql } from '@apollo/client';

export const createPublicSessionMutation = gql`
  mutation createPublicSession($input: CreatePublicSessionInput) {
    createPublicSession(input: $input) {
      accessToken
      client
      uid
    }
  }
`;
