import { Line2Type } from 'types';

export const addressLine2Options: Array<{
  label: string;
  value: `${Line2Type}`;
}> = [
  {
    label: 'None',
    value: Line2Type.NONE,
  },
  {
    label: 'Apartment',
    value: Line2Type.APARTMENT,
  },
  {
    label: 'Unit',
    value: Line2Type.UNIT,
  },
  {
    label: 'Suite',
    value: Line2Type.SUITE,
  },
  {
    label: 'Lot',
    value: Line2Type.LOT,
  },
  {
    label: 'Other',
    value: Line2Type.OTHER,
  },
];
