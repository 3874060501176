import { EventTypeMap } from '@updater/ui-tracker';
import { Events } from './tracking.types';
import { createAnalyticsEvents } from './createAnalyticsEvents';

export const eventTypeMap: EventTypeMap = {
  marketingSites: createAnalyticsEvents(Events, [
    'viewed',
    'submitted',
    'clicked',
    'changed',
    'reset',
    'selected',
    'displayed',
    'measured',
    'tracked',
    'accepted',
  ]),
};
