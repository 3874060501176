import { MicrositeCustomTheme, MicrositeTheme } from 'types';
import { baseTheme } from '../baseTheme';

// ==================================
// BEGIN CUSTOM THEMING FOR MICROSITE
// ==================================

const customTheme: MicrositeCustomTheme = {
  name: 'WindstreamTheme',
  code: 'windstream',
  colors: {
    headerBackground: '#fff',
    headerText: '#212529',
    headerBorder: '#e0e7e9',
    background: '#f9f9f9',
    text: '#000000',
    footerBackground: '#f2f2f2',
    footerText: '#8c8c8c',
    footerBorder: '#e0e7e9',
    progressBar: '#ef6724',
  },
  logoSize: {
    width: '140px',
    height: '56px',
  },
  baseColors: {
    link: '#ef6724',
    cta: '#ef6724',
    hover: '#ef9123',
  },
};

// ==================================
// END CUSTOM THEMING FOR MICROSITE
// ==================================

const mergedTheme = Object.assign(baseTheme, {
  colors: {
    ...baseTheme.colors,
    ...customTheme.baseColors,
  },
});

export const WindstreamTheme: MicrositeTheme = {
  ...mergedTheme,
  customTheme,
};
