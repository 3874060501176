import { createContext } from 'react';
import { UseOffersResult } from '@updater/ui-widgets';

export interface OffersContextInterface {
  primaryData: Partial<UseOffersResult>;
  secondaryData?: Partial<UseOffersResult>;
  tertiaryData?: Partial<UseOffersResult>;
}

export const OffersContext = createContext<OffersContextInterface>({
  primaryData: {
    availableOffers: null,
    providerSummaries: null,
    loading: false,
  },
});
