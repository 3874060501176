import { FC } from 'react';
import { TvInternetOffer } from '@updater/consumer-graph';
import { ProviderConfig } from './ProviderConfig';
import { Address } from './Address';
import { MicrositeTheme } from './MicrositeTheme';
import { Qual } from './Qual';

export enum CheckoutType {
  LEAD_MODE = 'lead_mode',
  ASYNC = 'async',
  CALL_MODE = 'call_mode',
  // Some providers won't let us sell their products on their marketing microsites.
  // They want us to capture the traffic, but if a user is eligible, they
  // want us to redirect automatically to *their* offers page immediately.
  // Spectrum is the only provider that currently does this
  PROVIDER_REDIRECT = 'provider_redirect',
}

export type ExtraTrackingDetails =
  | 'user_uuid'
  | 'property_uuid'
  | 'originator_id'
  | 'promo_id'
  | 'user_email';

export enum ShopType {
  // Single provider means we're only selling 1 provider on this site.
  // These microsites will have 3 different s12y queries:
  // (1) one for the main provider alone
  // (2) one for other providers that are the same type as the main provider
  //   (if the main provider is an MSO, the other providers will be the other MSOs)
  // (3) one for *all* providers
  SINGLE_PROVIDER = 'single',
  // Multi provider means more than one provider is sent to s12y
  MULTI_PROVIDER = 'multi',
  // Synthetic is anything in between (> 2 but < all)
  SYNTHETIC = 'synthetic',
}

interface CustomComponents {
  // Whether we want a custom confirmation page
  Confirmation?: FC;
  // Whether we want a custom Lead Error page
  LeadError?: FC;
  // Whether we want a custom loading component
  PageLoading?: FC<any>;
}

interface Urls {
  development?: string;
  staging?: string;
  qa?: string;
  production?: string;
}

type OptionalFieldsType = 'gclid' | 'partnerPropertyId' | null;

type OptionalFieldsMap = [
  OptionalFieldsType,
  OptionalFieldsType,
  OptionalFieldsType,
  OptionalFieldsType,
  OptionalFieldsType
];

export interface MicrositeConfig {
  // `name` is a value that should match the typescript class name.
  // it's used only in logging
  name: string;

  // optional: promoIds are attached to originatorIds, may be useful in tracking
  originatorId?: string;

  // `micrositeCode` is the value passed from the WordPress pages.
  // Provider App checks this value when the app initializes to determine
  // what microsite config to load!
  micrositeCode: string;

  // Whether this microsite is a single / multi / etc.
  shopType: ShopType;

  // The primary domain of the microsite
  domain: string;

  // Various edge cases will send the user to a dead end if they're not
  // eligible or for other reasons.  We offer to send them back to the
  // homepage of the site if this happens.  Should be a complete URL.
  homepage: string;

  // Whatever you want in the <title> tag
  title: string;

  // Some microsites have special pages to sell internet plans if the
  // user enters a commercial address (Xfinity is one of these).  If you
  // provide a value here, there's special logic to pop up a modal that
  // offers to sell you a commercial plan.  This should a complete URL.
  commercialHomepage?: string;

  // Phone Numbers are hard.  This is the CallRail swap number.  CallRail
  // is a service we use that creates real phone numbers for customers to call
  // on the fly, and it's used for call center tracking and attributions.
  // Each provider generally has a single "swap number" that we use.  CallRail's
  // javascript will look for the swap number anywhere it appears, and dynamically
  // replace it with the custom number for tracking and attribution purposes.
  // See the CallRailPhone component for details on how this works.
  // If you need to add or change a phone number for a microsite, go here:
  //    https://app.callrail.com/settings/a/501092119/routing?company_id=947929203
  phoneNumber: string;

  // Some microsites have different swap numbers for the Lead Mode confirmation page.
  // Leave this blank if you can't find one in CallRail's dashboard.
  // Note that s12y will also return a confirmation number with the createLead mutation.
  // That phone number should take precedence, with this one acting as a fallback
  leadModePhoneNumber: string;

  // We have two kinds of checkout:
  // (1) Lead Mode -- this is where a user enters their name and contact info,
  //   and they get a confirmation number and instructions to call to complete
  //   their order
  // (2) Async -- this is where they can checkout async through a buyflow
  // (3) Call -- this is where they are required to call in to order
  // Indicate the default checkout type here.
  defaultCheckoutType: CheckoutType;

  // Optional: Specify the checkout type when the offers at the address have an active service
  activeServiceCheckoutType?: CheckoutType;

  // This is the default visual theme that is loaded with this microsite.
  // Note that there is business logic to "swap" themes, so the visual theming
  // is kept completely separate from the microsite configuration.
  defaultTheme: MicrositeTheme;

  // Do we need a DC session ID for this microsite for any reason?  Since s12y
  // returns the Falcon TID, we don't need a DC session if the user isn't
  // leaving provider app.  Currently the only user journies requiring a DC
  // session ID are:
  // (1) xfinity async guest-buyflow, which reads off DC
  // Setting this to `true` will trigger the polling behavior to get a DC
  // session ID for the primaryData request.
  needsDcSession: boolean;

  // Some microsites require that we display the Updater logo in addition to
  // the other brand logo.  Set this to true to make the Updater logo appear in
  // the header.
  hasUpdaterCobranding?: boolean;

  // Are we cross-selling to updater.com/internet?
  updaterCrossSellActive: boolean;

  // Does this instance of Provider App appear in an iframe?
  // This will remove the header and the footer!
  isIFramed: boolean;

  // Optional: Specify a theme, different support number and link back for the guest-buyflow
  asyncConfig?: {
    theme?: 'updater';
    supportPhoneNumber?: string;
    // to suppress linkback, don't include headerLogoLinkUrl
    headerLogoLinkUrl?: string;
  };

  // Optional: Google Tag Manager code for this microsite
  // This is usually sent in the qual from the WordPress pages
  // If it does come in the qual, this value will be IGNORED
  // If it does NOT come in the qual, this value will be used
  gtmCode?: string;

  // Optional: suppress standalone NormalizedAddress check
  suppressNormalizedAddressCheck?: boolean;

  // Optional: suppress link back on header logo
  suppressHeaderLogoLinkBack?: boolean;

  // Optional: enable event tracking for Updater terms/conditions/privacy agreement
  trackUpdaterTermsAgreement?: boolean;

  optionalFieldsMap?: OptionalFieldsMap;

  // This is value used by the data team; it's sent as a serviceability parameter.
  // Contact the backend and data teams to define what this should be.
  source: string;

  // If the user enters an address that doesn't get any offers back, the
  // product behavior is to re-prompt them to validate their address in
  // a modal.  This is because users sometimes enter weird stuff in their
  // address that somehow passes the normalization on the WordPress pages.
  // We re-prompt them to validate their address this many times before
  // showing them a "dead end" modal to call our call center.
  noOffersRetry: number;

  // Function that determines the checkout flow based on the offer
  // object from serviceability
  getCheckoutType: (offer: TvInternetOffer) => CheckoutType;

  // any component overrides needed for this microsite/theme
  customComponents?: CustomComponents;

  /**
   * List of extra tracking details to enable.
   */
  enabledTrackingDetails?: ExtraTrackingDetails[];
  /**
   * Option to hide the Back Button on first page(quiz)-
   * back button would be confusing and not be functional
   * if app is opened in a new tab/window
   */
  hideFirstPageBackButton?: boolean;
  /**
   * temporary option only for Appfolio microsite, to skip quiz and display an Appfolio-specific static landing page
   */
  enableAppfolioLanding?: boolean;
  skipToPlansOnRevisit?: boolean;
  /**
   * Option to hide the Support Information box on the lead mode form,
   * useful in forcing the user to submit a lead
   */
  hideSupportInfoBox?: boolean;
}

export interface CrossSellInput {
  providerConfig?: ProviderConfig;
  serviceabilityOffer?: TvInternetOffer;
  baseUrl?: string;
  dcFwsTid?: string;
  dcSessionId?: string;
  uuid?: string;
  address?: Address;
  qual?: Qual;
}

export enum CrossSellType {
  CROSS_SELL_SHOP = 'cross_sell_shop',
  CROSS_SELL_RESKIN = 'cross_sell_reskin',
  CROSS_SELL_REDIRECT = 'cross_sell_redirect',
}

export interface CrossSellAction {
  crossSellType: CrossSellType;
  reskinProviderConfig?: ProviderConfig;
  crossSellUrl?: string;
}

// SINGLE PROVIDER MICROSITES
// These are microsites where we sell offers from 1 provider only
// by default.  These sites have special cross-sell behavior based
// on this configuration.  They are generally matched 1-to-1 with
// a theme.
export interface SingleProviderConfig extends MicrositeConfig {
  // The "main" provider for this microsite.
  mainProvider: ProviderConfig;

  // A function to get the cross-sell URL based on given input.
  getCrossSellAction: (data: CrossSellInput) => CrossSellAction;

  // This is an ORDERED LIST of cross-sell providers for this
  // microsite.  The general rules for cross-selling are:
  // (a) if the mainProvider is an MSO, we can cross-sell other MSOs
  // (b) if the mainProvider is a Telco, we can cross-sell other Telcos
  // In order to maximize conversion rates, we display only a single
  // provider in the cross-sell experience based on business metrics.
  // If there are offers available from multiple providers here, the
  // app will scan the available list from index 0 to n and choose the
  // cross-sell provider with the LOWEST index.
  // There are funky rules with cross-selling, so you must explicitly
  // and exhaustively define a cross-sell list for every microsite.
  crossSellProviders?: ProviderConfig[];

  // This is a list of providers that we can cross-sell on Updater.com/internet,
  // but *not* direct cross-sell.  The business rules are a bit confusing,
  // but this list will generally be the *inverse* of the crossSellProviders
  // above.  Meaning, if crossSellProviders above are a list of MSOs, this will
  // be a list of Telcos and vice versa.
  updaterCrossSellProviders?: ProviderConfig[];

  // A list of seconary providers that we consider "primary"
  // for shops with complex synthetic rules
  syntheticProviders?: ProviderConfig[];

  // These are URLs for routing users into the guest-buyflow app
  // (currently only for Xfinity)
  guestBuyflowUrls?: Urls;

  // These are the legacy Shop App urls used for cross-selling
  uxBuyflowUrls?: Urls;

  // If the checkout type is PROVIDER_REDIRECT, we must supply a function
  // to generate the provider redirect URL.  This is currently the same
  // function as the cross-sell URLs, but these are different product
  // use cases so we should really keep them separate.
  getProviderRedirectUrl?: (data: CrossSellInput) => string;
}

// MULTIPLE PROVIDER MICROSITES
// These are microsites where we can sell any number of providers on
// the same page, just like we do in Consumer App.  These microsites
// have *no* cross-sell functionality because cross-selling as a concept
// makes no sense if we can sell everything on the same page.
// For these sites, all you need to do is define a list of providers
// that this site should query serviceability for and display.  Generally
// this is *every* provider, though the sales and business teams will
// want to tweak this based on individual rules from certain providers, so
// you must exhaustively list every provider
export interface MultiProviderConfig extends MicrositeConfig {
  providers: ProviderConfig[];

  // These are URLs for routing users into the guest-buyflow app
  // (currently only for Xfinity)
  // TODO: To support more than one provider, we need to implement a way to
  // support multiple sets of guestBuyflowUrls
  guestBuyflowUrls?: Urls;
}

export type MicrositeConfigType = SingleProviderConfig | MultiProviderConfig;
