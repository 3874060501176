import {
  Experiment,
  FeatureResult,
  GrowthBook,
  GrowthBookProvider,
  Result,
} from '@growthbook/growthbook-react';

import { datadogRum } from '@datadog/browser-rum';
import { TrackEventInput } from '@updater/ui-tracker';
import { useQual } from 'hooks/useQual';
import { useCallback, useEffect, useMemo } from 'react';
import { useTracking } from 'react-tracking';
import { useEnvironment } from 'services/environment';

type TrackingCallback = (
  experiment: Experiment<any>,
  result: Result<any>
) => void;
type FeatureUsageCallback = (featureKey: string, result: FeatureResult) => void;

const trackingSubscriptions = new Set<TrackingCallback>();
const featureUsageSubscriptions = new Set<FeatureUsageCallback>();

export const useGrowthbookTracking = (cb: TrackingCallback) => {
  useEffect(() => {
    trackingSubscriptions.add(cb);
    return () => {
      trackingSubscriptions.delete(cb);
    };
  }, [cb]);
};

export const useGrowthbookFeatureUsage = (cb: FeatureUsageCallback) => {
  useEffect(() => {
    featureUsageSubscriptions.add(cb);
    return () => {
      featureUsageSubscriptions.delete(cb);
    };
  }, [cb]);
};

const datadogFeatureNotifier: FeatureUsageCallback = (featureKey, result) => {
  datadogRum.addFeatureFlagEvaluation(featureKey, result.value);
};

const useCreateGrowthbookClient = () => {
  const env = useEnvironment();
  const growthbookAPIHost = env.growthbookApiHost;
  const growthbookSDKKey = env.growthbookSdkKey;
  const enableDevMode = !env.inProduction;

  return useMemo(() => {
    return new GrowthBook({
      apiHost: growthbookAPIHost,
      clientKey: growthbookSDKKey,
      enableDevMode,
      trackingCallback: (experiment, result) => {
        console.log({ experiment, result, trackingSubscriptions });
        trackingSubscriptions.forEach((cb) => {
          try {
            cb(experiment, result);
          } catch (e) {
            console.error(e);
          }
        });
      },
      onFeatureUsage: (featureKey, result) => {
        featureUsageSubscriptions.forEach((cb) => {
          try {
            cb(featureKey, result);
          } catch (e) {
            console.error(e);
          }
        });
      },
    });
  }, [growthbookAPIHost, growthbookSDKKey, enableDevMode]);
};

export type GrowthbookWrapperProps = {
  children: React.ReactNode;
};

export const GrowthbookWrapper: React.FC<GrowthbookWrapperProps> = ({
  children,
}) => {
  const gbClient = useCreateGrowthbookClient();

  useEffect(() => {
    // Load features from the GrowthBook API
    gbClient.loadFeatures({
      timeout: 4000,
    });
  }, []);

  const { uuid } = useQual();

  const { trackEvent } = useTracking<TrackEventInput<unknown>>({
    domain: 'growthbook',
  });

  const trackingCallback = useCallback<TrackingCallback>(
    (experiment, result) => {
      trackEvent({
        object: 'experiment',
        verb: 'tracked',
        details: {
          experiments: {
            [experiment.key]: result.value,
          },
          bucketingId: result.hashValue,
        },
      });
      trackEvent({
        object: 'user',
        verb: 'updated',
        details: {
          experiments: {
            [experiment.key]: result.value,
          },
          bucketingId: result.hashValue,
        },
      });
      trackEvent({
        object: 'growthbookTrack',
        verb: 'tracked',
        details: {
          eventKey: experiment.key,
          result,
          bucketingId: result.hashValue,
        },
      });
    },
    [trackEvent]
  );

  useGrowthbookTracking(trackingCallback);

  useGrowthbookFeatureUsage(datadogFeatureNotifier);

  useEffect(() => {
    gbClient.setAttributes({ id: uuid });
  }, [gbClient, uuid]);

  return (
    <GrowthBookProvider growthbook={gbClient}>{children}</GrowthBookProvider>
  );
};
