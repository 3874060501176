import { eventType } from '@updater/ui-tracker';
import { AnalyticsObject, TrackEventVerb } from './tracking.types';

export const createAnalyticsEvents = (
  object: Record<string, string | number>,
  verbs: TrackEventVerb[]
) => {
  const analyticsObject: AnalyticsObject = {};
  const actions: Record<string, unknown> = {};
  verbs.forEach((v: string) => {
    actions[v] = eventType();
  });
  Object.keys(object).forEach((i) => {
    analyticsObject[object[i]] = actions;
  });
  return analyticsObject;
};
