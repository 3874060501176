import { ProviderConfig, ProviderTypes } from 'types';
import { WaveTheme } from 'config/themes';

export const Wave: ProviderConfig = {
  providerType: ProviderTypes.MSO,
  providerCode: 'wave',
  dcCode: 'Wave',
  name: 'Wave',
  displayName: 'Wave',
  reskinTheme: WaveTheme,
  reskinTitle: 'Wave Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'none',
};
