import { ProviderConfig, ProviderTypes } from 'types';
import { RCNTheme } from 'config/themes';

export const RCN: ProviderConfig = {
  providerType: ProviderTypes.MSO,
  providerCode: 'rcn',
  dcCode: 'RCN',
  name: 'RCN',
  displayName: 'RCN',
  reskinTheme: RCNTheme,
  reskinTitle: 'RCN Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'none',
};
