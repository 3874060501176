import { MicrositeContext } from 'context';
import { useQual } from 'hooks';
import { useContext } from 'react';

export const useOptionalFieldsById = (): Record<string, string> => {
  const micrositeConfig = useContext(MicrositeContext);
  const { optionalFields } = useQual() || {};
  const optionalFieldsById = {};
  micrositeConfig.optionalFieldsMap?.forEach((key, i) => {
    if (key) optionalFieldsById[key] = optionalFields?.[`option${i + 1}`];
  });
  // enforce option5 to always be gclid
  optionalFieldsById['gclid'] = optionalFields?.option5;
  return optionalFieldsById;
};
