import { MicrositeCustomTheme, MicrositeTheme } from 'types';
import { baseTheme } from '../baseTheme';

// ==================================
// BEGIN CUSTOM THEMING FOR MICROSITE
// ==================================

const customTheme: MicrositeCustomTheme = {
  name: 'EarthlinkTheme',
  code: 'earthlink',
  colors: {
    headerBackground: '#fff',
    headerText: '#212529',
    headerBorder: '#e0e7e9',
    background: '#FFFFFF',
    text: '#000000',
    footerBackground: '#000',
    footerText: '#fff',
    footerBorder: 'transparent',
    progressBar: '#f68d1f',
  },
  logoSize: {
    width: '165px',
    height: '36px',
  },
  baseColors: {
    link: '#f68d1f',
    cta: '#f68d1f',
    hover: '#f2800a',
  },
};

// ==================================
// END CUSTOM THEMING FOR MICROSITE
// ==================================

const mergedTheme = Object.assign(baseTheme, {
  colors: {
    ...baseTheme.colors,
    ...customTheme.baseColors,
  },
});

export const EarthlinkTheme: MicrositeTheme = {
  ...mergedTheme,
  customTheme,
};
