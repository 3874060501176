import { MicrositeCustomTheme, MicrositeTheme } from 'types';
import { baseTheme } from '../baseTheme';

// ==================================
// BEGIN CUSTOM THEMING FOR MICROSITE
// ==================================

const customTheme: MicrositeCustomTheme = {
  name: 'CoxTheme',
  code: 'cox',
  colors: {
    headerBackground: '#fff',
    headerText: '#212529',
    headerBorder: '#e0e7e9',
    headerGradient: 'to right, #009ae0 27%, #00d258 70%',
    background: '#FFFFFF',
    text: '#000000',
    footerBackground: '#f3f5f8',
    footerText: '#8c8c8c',
    footerBorder: 'transparent',
    footerGradient: 'to right, #009ae0 27%, #00d258 70%',
    progressBar: '#0177eb',
  },
  logoSize: {
    width: '90px',
    height: '48px',
  },
  baseColors: {
    link: '#0177eb',
    cta: '#0177eb',
    hover: '#016ad2',
  },
};

// ==================================
// END CUSTOM THEMING FOR MICROSITE
// ==================================

const mergedTheme = Object.assign(baseTheme, {
  colors: {
    ...baseTheme.colors,
    ...customTheme.baseColors,
  },
});

export const CoxTheme: MicrositeTheme = {
  ...mergedTheme,
  customTheme,
};
