import { MicrositeCustomTheme, MicrositeTheme } from 'types';
import { baseTheme } from '../baseTheme';

// ==================================
// BEGIN CUSTOM THEMING FOR MICROSITE
// ==================================

const customTheme: MicrositeCustomTheme = {
  name: 'DishTheme',
  code: 'dish',
  colors: {
    headerBackground: '#fff',
    headerText: '#212529',
    headerBorder: '#e0e7e9',
    background: '#f9f9f9',
    text: '#000000',
    footerBackground: '#000010',
    footerText: '#fff',
    footerBorder: 'transparent',
    progressBar: '#e41932',
  },
  logoSize: {
    width: '100px',
    height: '40px',
  },
  baseColors: {
    link: '#e41932',
    cta: '#e41932',
    hover: '#cd162d',
  },
};

// ==================================
// END CUSTOM THEMING FOR MICROSITE
// ==================================

const mergedTheme = Object.assign(baseTheme, {
  colors: {
    ...baseTheme.colors,
    ...customTheme.baseColors,
  },
});

export const DishTheme: MicrositeTheme = {
  ...mergedTheme,
  customTheme,
};
