import { Address, Qual, SiteMode, ProviderConfig } from 'types';
import { TvInternetOffer } from '@updater/consumer-graph';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface Store {
  crossSellModal: boolean;
  showHelpChoosing: boolean;
  wantsNewAddress: boolean;
  showFilterModal: boolean;
  showSortModal: boolean;
  unavailableAddressCount: number;
  dismissCommercial: boolean;
  skipNormalization: boolean;
  asyncChosenOffer: TvInternetOffer | undefined;
  qual: Qual;
  reskinProvider: ProviderConfig | undefined;
  siteMode: SiteMode;
  visitingQual: string;
  acceptedUpdaterTerms: boolean;
  setCrossSellModal: (crossSellModal?: boolean) => void;
  setShowHelpChoosing: (showHelpChoosing?: boolean) => void;
  setWantsNewAddress: (wantsNewAddress?: boolean) => void;
  setShowFilterModal: (showFilterModal?: boolean) => void;
  setShowSortModal: (showSortModal?: boolean) => void;
  setAddress: (address: Address, skipNormalization?: boolean) => void;
  incrementUnavailableAddressCount: () => void;
  setDismissCommercial: (dismissCommercial?: boolean) => void;
  setReskinProvider: (reskinProvider: ProviderConfig | undefined) => void;
  setAsyncChosenOffer: (offer: TvInternetOffer) => void;
  setQualData: (data: any) => void;
  setQual: (fullQual: Qual) => void;
  setSiteMode: (newMode: SiteMode) => void;
  deleteReskinProvider: () => void;
  setVisitingQual: (visitingQual: string) => void;
  setAcceptedUpdaterTerms: (acceptedUpdaterTerms: boolean) => void;
}
export const providerAppStore = 'provider-app-store';

export const useStore = create<Store>()(
  persist(
    (set) => ({
      crossSellModal: false,
      // Flag for showing dialog helping the user choosing an internet plan
      showHelpChoosing: false,
      // Flag for showing dialog to let the user to change their address
      wantsNewAddress: false,
      // Flag for showing filters modal in small breakpoints
      showFilterModal: false,
      // Flag for showing sort modal in small breakpoints
      showSortModal: false,
      // Flag to count how many times the available address modal has been shown
      unavailableAddressCount: 0,
      // Flag to dismiss the commercial address modal if they want to force
      // to qualify for offers at an address that our normalization service
      // has identified as commercial
      dismissCommercial: false,
      // If the user chooses a suggested address from the provider API, we need
      // skip address normalization on subsequent calls to s12y
      skipNormalization: false,
      visitingQual: '',
      acceptedUpdaterTerms: false,
      qual: {},
      asyncChosenOffer: undefined,
      reskinProvider: undefined,
      siteMode: SiteMode.DEFAULT_MODE,

      setCrossSellModal: (crossSellModal = true) => {
        set({
          crossSellModal,
        });
      },
      setShowHelpChoosing: (showHelpChoosing = true) => {
        set({
          showHelpChoosing,
        });
      },
      setWantsNewAddress: (wantsNewAddress = true) => {
        set({
          wantsNewAddress,
        });
      },
      setShowFilterModal: (showFilterModal = true) => {
        set({
          showFilterModal,
        });
      },
      setShowSortModal: (showSortModal = true) => {
        set({
          showSortModal,
        });
      },
      setDismissCommercial: (dismissCommercial = true) => {
        set({
          dismissCommercial,
        });
      },
      setAddress: (address: Address, skipNormalization = false) => {
        set((state) => ({
          qual: {
            ...state.qual,
            ...address,
            zip: address?.postalCode,
          },
          skipNormalization,
          wantsNewAddress: false,
        }));
      },
      incrementUnavailableAddressCount: () => {
        set((state) => ({
          unavailableAddressCount: state.unavailableAddressCount + 1,
        }));
      },
      setReskinProvider: (reskinProvider: ProviderConfig) => {
        set({
          reskinProvider,
        });
      },
      setAsyncChosenOffer: (asyncChosenOffer: TvInternetOffer) => {
        set({
          asyncChosenOffer,
        });
      },
      setQualData: (data: any) => {
        set((state) => ({
          ...state.qual,
          ...data,
        }));
      },
      setQual: (qual: Qual) => {
        set({
          qual,
        });
      },
      setSiteMode: (siteMode: SiteMode) => {
        set({
          siteMode,
        });
      },
      deleteReskinProvider: () => {
        set({
          reskinProvider: undefined,
        });
      },
      setVisitingQual: (visitingQual: string) => {
        set({
          visitingQual,
        });
      },
      setAcceptedUpdaterTerms: (acceptedUpdaterTerms: boolean) => {
        set({
          acceptedUpdaterTerms,
        });
      },
    }),
    {
      name: providerAppStore,
      // If the user refreshes the page, goes to another site
      // or comes back later, we only want the qual to persist,
      // not any of the other boolean data or counts.
      partialize: (state) => ({
        qual: state.qual,
        visitingQual: state.visitingQual,
        acceptedUpdaterTerms: state.acceptedUpdaterTerms,
      }),
    }
  )
);
