import { ProviderConfig, ProviderTypes } from 'types';
import { QuantumFiberTheme } from 'config/themes';

export const QuantumFiber: ProviderConfig = {
  providerType: ProviderTypes.LEC,
  providerCode: 'centurylink',
  dcCode: 'centurylink',
  name: 'Quantum Fiber',
  displayName: 'Quantum Fiber',
  reskinTheme: QuantumFiberTheme,
  reskinTitle: 'Quantum Fiber Authorized Dealer',
  showPricingDisclaimer: false,
  // https://upd-home-platform.slack.com/archives/C0621PMJKUG/p1710970987540529?thread_ts=1710969879.366809&cid=C0621PMJKUG
  broadbandDisplayType: 'updater_generated',
};
