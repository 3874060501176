import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useEnvironment } from 'services/environment';

export function MockServiceWorker({ children }) {
  const router = useRouter();
  const env = useEnvironment();
  const mockingInitialized = useRef(false);
  const [mockInitialized, setMockInitialized] = useState(false);

  function setInitialized() {
    setMockInitialized(true);
  }

  useEffect(() => {
    if (router.isReady && !mockingInitialized.current) {
      mockingInitialized.current = true;
      const environmentMocked = env.apiMocking;
      const queryMocked = !env.inProduction && 'mock' in router.query;
      if (environmentMocked || queryMocked) {
        const { prepare } = require(`mocks`);
        const mockPromise = prepare() as Promise<any>;
        mockPromise.then(setInitialized).catch(setInitialized);
      } else {
        setInitialized();
      }
    }
  }, [router.isReady, env]);

  if (!mockInitialized) {
    return null;
  }

  return <>{children}</>;
}
