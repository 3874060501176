import {
  EventTypeMap,
  TrackEventInput,
  Tracker as UITracker,
} from '@updater/ui-tracker';
import { useQual } from 'hooks';
import { useEffect, useMemo } from 'react';
import { useTracking } from 'react-tracking';
import { useEnvironment } from 'services/environment';
import { appName } from './constants';
import { useCreateBaseTrackingEvent } from './createBaseTrackingEvent';
import { eventTypeMap } from './eventTypeMap';

export function Tracker({ children }) {
  const {
    name: envName,
    eventStream,
    eventStreamToken,
    inProduction,
  } = useEnvironment();
  const { uuid, userEmail } = useQual();
  const { addExtraEventDetails } = useCreateBaseTrackingEvent();

  const [tracker, events] = useMemo(() => {
    const trackerConfig = {
      eventTypeMap,
      environment: inProduction ? envName : 'sandbox',
      destinations: {
        eventstream: {
          app: appName,
          url: eventStream,
          authorization: eventStreamToken,
        },
      },
      channel: appName,
      debug: !inProduction,
      appName,
    };
    const tracker = new UITracker<EventTypeMap>(trackerConfig);
    tracker.identify({
      uuid,
      email: userEmail ?? '',
      moverId: '',
    });
    return [tracker, tracker.Events.marketingSites];
  }, [envName, eventStream, eventStreamToken, uuid, inProduction, userEmail]);

  // dispose of ui-tracker if/when unmounted
  useEffect(() => tracker.destroy, [tracker]);

  const { Track } = useTracking<TrackEventInput<unknown>>(
    {
      appName,
      domain: appName,
    },
    {
      dispatch: (event: any) => {
        const eventWithExtraDetails = addExtraEventDetails(event);
        if (eventWithExtraDetails.eventName) {
          const { eventName, verb, details } = eventWithExtraDetails;
          try {
            tracker.track(events[eventName][verb], details);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.info('Event error', { eventName, verb, events });
            // eslint-disable-next-line no-console
            console.error(error);
          }
        } else {
          tracker.trackUntyped(eventWithExtraDetails);
        }
      },
    }
  );

  return <Track>{children}</Track>;
}
