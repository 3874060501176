import { ProviderConfig, ProviderTypes } from 'types';
import { OptimumTheme } from 'config/themes';

export const Optimum: ProviderConfig = {
  providerType: ProviderTypes.MSO,
  providerCode: 'optimum',
  dcCode: 'Optimum',
  name: 'Optimum',
  displayName: 'Optimum',
  reskinTheme: OptimumTheme,
  reskinTitle: 'Optimum Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'updater_generated',
};
