import { ProviderConfig, ProviderTypes } from 'types';
import { FrontierTheme } from 'config/themes';

export const Frontier: ProviderConfig = {
  providerType: ProviderTypes.LEC,
  providerCode: 'frontier',
  dcCode: 'Frontier',
  name: 'Frontier',
  displayName: 'Frontier',
  reskinTheme: FrontierTheme,
  reskinTitle: 'Frontier Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'linkout_url',
};
