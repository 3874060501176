import { MicrositeCustomTheme, MicrositeTheme } from 'types';
import { baseTheme } from '../baseTheme';

// ==================================
// BEGIN CUSTOM THEMING FOR MICROSITE
// ==================================

const customTheme: MicrositeCustomTheme = {
  name: 'TDSTheme',
  code: 'TDS',
  colors: {
    headerBackground: '#fff',
    headerText: '#212529',
    headerBorder: '#e0e7e9',
    background: '#f9f9f9',
    text: '#000000',
    footerBackground: '#353535',
    footerText: '#fff',
    footerBorder: 'transparent',
    progressBar: '#19388a',
  },
  logoSize: {
    width: '120px',
    height: '48px',
  },
  baseColors: {
    link: '#19388a',
    cta: '#19388a',
    hover: '#25acf2',
  },
};

// ==================================
// END CUSTOM THEMING FOR MICROSITE
// ==================================

const mergedTheme = Object.assign(baseTheme, {
  colors: {
    ...baseTheme.colors,
    ...customTheme.baseColors,
  },
});

export const TDSTheme: MicrositeTheme = {
  ...mergedTheme,
  customTheme,
};
