interface InternetSpeedGuideline {
  activity: string;
  minDownloadSpeed: string;
  recommendedSpeed: string;
}

export const internetSpeedGuidelines: Array<InternetSpeedGuideline> = [
  {
    activity: 'Browsing the web, email & social media',
    minDownloadSpeed: '1 Mbps',
    recommendedSpeed: '5+ Mbps',
  },
  {
    activity: 'High-definition video streaming',
    minDownloadSpeed: '5 Mbps',
    recommendedSpeed: '25+ Mbps',
  },
  {
    activity: 'Ultra high-definition 4K video streaming',
    minDownloadSpeed: '25 Mbps',
    recommendedSpeed: '25+ Mbps',
  },
  {
    activity: 'Online gaming',
    minDownloadSpeed: '4 Mbps',
    recommendedSpeed: '50+ Mbps',
  },
  {
    activity: 'Frequent large file downloading',
    minDownloadSpeed: '10 Mbps',
    recommendedSpeed: '50+ Mbps',
  },
  {
    activity: 'Video conferencing',
    minDownloadSpeed: '8 Mbps',
    recommendedSpeed: '50+ Mbps',
  },
];
