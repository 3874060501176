import {
  InternetProductType,
  PhoneProductType,
  ServiceabilityChargeType,
  ServiceabilityDisclaimerDisplayLocation,
  ServiceabilityInternetSpeedUnit,
  ServiceabilityProviderAvailability,
  ServiceabilityResultStatus,
  ServiceabilityTermUnit,
  TvProductType,
} from 'types/generated/globalTypes';
import { AvailableOffersResponse } from 'services/apollo/queries';

export const availableOffers: AvailableOffersResponse = {
  availableOffers: {
    providerSummaries: [
      {
        availability: 'available' as ServiceabilityProviderAvailability,
        status: 'cache' as ServiceabilityResultStatus,
        queryAddress: {
          street: '2007 E 3rd St',
          unit: null,
          city: 'Pueblo',
          state: 'CO',
          postalCode: '81001',
          __typename: 'ServiceabilityAddress',
        },
        addressSuggestions: null,
        error: null,
        falconIdentifier: '728843992',
        provider: {
          id: '125887433',
          code: 'xfinity',
          name: 'Xfinity',
          featured: null,
          exclusive: null,
          integrated: null,
          displayName: 'Xfinity',
          url: null,
          linkout: null,
          phoneNumber: null,
          logo: {
            baseUrl: 'https://res.cloudinary.com/updater-staging/image/upload',
            id: 'v1655833482/tv_internet_provider_logo_125887433',
            url: 'https://res.cloudinary.com/updater-staging/image/upload/v1598987000/tv_internet_provider_logo_125887433',
            __typename: 'Image',
          },
          validationSteps: null,
          config: {
            supportExistingCustomer: null,
            supportAddressSuggestions: null,
            __typename: 'ServiceabilityProviderConfig',
          },
          __typename: 'ServiceabilityProvider',
        },
        __typename: 'ServiceabilityProviderSummaries',
      },
    ],
    request: {
      id: 'v1598987000',
      address: {
        street: null,
        unit: null,
        city: null,
        state: null,
        postalCode: null,
        __typename: 'ServiceabilityAddress',
      },
      __typename: 'ServiceabilityRequest',
    },
    offers: [
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: {
            name: 'Gigabit',
            downloadSpeed: {
              value: 1200,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            uploadSpeed: {
              value: 35,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            type: 'fiber' as InternetProductType,
            features: null,
            __typename: 'ServiceabilityInternetProduct',
          },
          phone: null,
          tv: null,
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'SystemRequirements:System recommendations to run Xfinity Internet Service with speeds up to 1 Gbps\r\nYour desktop or laptop should have the following:\r\n10/100/1000 Gigabit Ethernet interface or adapter for wired connection.\r\nStandard Cat5e Ethernet cable to facilitate a wired connection is recommended. Please be aware that due to Ethernet overhead on a 1G link, actual speeds might be limited to 940 Megabytes per second.\r\nCPU: Intel Dual Core i5 2.5GHz or better or AMD Dual Core K10 2.5GHz or better\r\nOS Requirements: Windows 8.1 (64 bit) or newer or Mac OSX 10.8 or newer\r\nMemory: 8 GB\r\nHard Drive: SSD recommended to achieve maximum download speeds\r\nHard Drive Space: 64 GB or larger\r\nMac OSX Browsers: Safari 6.2.8 or newer or Firefox 40.0.3 or newer or Chrome 44.0.2403 or newer\r\nWindows Browsers: IE 11 or newer; MS Edge browser (Windows 10); Firefox 40.0.3 or newer; Chrome 44.0.2403 or newer\r\nExample machines: Dell Latitude E7450, 2013 Macbook Pro\r\nIf you have hardware, software and related equipment configurations that can connect to the Internet but that are different from the above recommendations, they will still work with your Xfinity Internet service; however, our testing has shown that these recommendations will work best to achieve your provisioned broadband speed. If you are not experiencing the speeds you expect, remember to test using a hardwired connection to the Internet. Also, make sure you are not in the process of downloading or uploading anything, close all programs using the Internet and power off any other devices that are sharing your home network. Remember, there are a number of external factors that may limit your speed; as a result, actual speeds may vary. For best performance, contact your device manufacturer/software provider for required upgrades, configuration updates, or troubleshooting guides.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'DetailsandRestrictions:Limited time offer. Restrictions apply. Not available in all areas. New residential customers only. Paperless Billing and Automatic Payments required. Discount will appear on statement within 45 days of enrollment. Equipment, installation, taxes and fees, and other applicable charges extra, and subject to change during and after the promo. After promo, or if any service is cancelled or downgraded, regular rates apply (subject to change). ©2022 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9626220462',
        extendedHighlights: ['Gigabit'],
        highlights: [
          'Unlimited devices',
          "Downloading for today's ultra-connected household",
          'Streaming through the ultimate WiFi experience',
          'Multiplayer gaming',
          'Upload speeds up to 35 Mbps',
          'Download speeds up to 1200 Mbps',
          'Unlimited devices',
          "Downloading for today's ultra-connected household",
          'Streaming through the ultimate WiFi experience',
          'Multiplayer gaming',
          'No minimum term contract required.',
        ],
        name: 'Gigabit',
        pricing: {
          price: 80,
          charges: [
            {
              amount: 80,
              description: null,
              type: 'short_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 24,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
            {
              amount: 110,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: null,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: 24,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: 24,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: '2-year price guarantee, no term contract required',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight1',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Unlimited data with WiFi equipment included for 24 months',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight2',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Flex 4K streaming TV box and Peacock Premium available at no extra cost.',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight3',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Save $30/mo for 24 months when you add Xfinity Mobile',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight4',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: '2-year price guarantee, no term contract required',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: {
            name: 'Superfast',
            downloadSpeed: {
              value: 600,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            uploadSpeed: {
              value: 20,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            type: 'fiber' as InternetProductType,
            features: null,
            __typename: 'ServiceabilityInternetProduct',
          },
          phone: null,
          tv: null,
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'SystemRequirements:System recommendations to run Xfinity Internet Service with speeds from 100 to 800 Mbps\r\nSoftware: Your desktop or laptop should be running a Microsoft mainstream-supported Windows operating system (on PC) or an Apple-supported OS X operating system (on Mac). You should use a recent version of one of the following browsers: Chrome, Firefox, Internet Explorer, or Safari.\r\nRelated Equipment: You can use a Cat5e or Cat6 Ethernet cable to facilitate your wired connection. A Cat5e Ethernet cable is not required for speeds up to 1 Gbps. Your desktop or laptop should have a 10/100/1000 Gigabit Ethernet interface or adapter for when you use a wired connection. Your computer or laptop also should have an Intel Core i5 (or faster) processor to support your speed.\r\nIf you have hardware, software and related equipment configurations that can connect to the Internet but that are different from the above recommendations, they will still work with your Xfinity Internet service; however, our testing has shown that these recommendations will work best to achieve your provisioned broadband speed. If you are not experiencing the speeds you expect, remember to test using a hardwired connection to the Internet. Also, make sure you are not in the process of downloading or uploading anything, close all programs using the Internet and power off any other devices that are sharing your home network. Remember, there are a number of external factors that may limit your speed; as a result, actual speeds may vary. For best performance, contact your device manufacturer/software provider for required upgrades, configuration updates, or troubleshooting guides.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'DetailsandRestrictions:Limited time offer. Restrictions apply. Not available in all areas. New residential customers only. Paperless Billing and Automatic Payments required. Discount will appear on statement within 45 days of enrollment. Equipment, installation, taxes and fees, and other applicable charges extra, and subject to change during and after the promo. After promo, or if any service is cancelled or downgraded, regular rates apply (subject to change). ©2022 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9626220466',
        extendedHighlights: ['Superfast'],
        highlights: [
          'Up to 11 devices at a time',
          'Downloading shows quickly',
          'Streaming in HD on multiple devices',
          'Upload speeds up to 20 Mbps',
          'Download speeds up to 600 Mbps',
          'Up to 11 devices at a time',
          'Downloading shows quickly',
          'Streaming in HD on multiple devices',
          'No minimum term contract required.',
        ],
        name: 'Superfast',
        pricing: {
          price: 60,
          charges: [
            {
              amount: 60,
              description: null,
              type: 'short_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 24,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
            {
              amount: 90,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: null,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: 24,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: 24,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: '2-year price guarantee, no term contract required',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight1',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Unlimited data with WiFi equipment included for 24 months',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight2',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Flex 4K streaming TV box and Peacock Premium available at no extra cost.',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight3',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Save $30/mo for 24 months when you add Xfinity Mobile',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight4',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: '2-year price guarantee, no term contract required',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: {
            name: 'Ultrafast',
            downloadSpeed: {
              value: 900,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            uploadSpeed: {
              value: 20,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            type: 'fiber' as InternetProductType,
            features: null,
            __typename: 'ServiceabilityInternetProduct',
          },
          phone: null,
          tv: null,
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'DetailsandRestrictions:Limited time offer. Restrictions apply. Not available in all areas. New residential customers only. Paperless Billing and Automatic Payments required. Discount will appear on statement within 45 days of enrollment. Equipment, installation, taxes and fees, and other applicable charges extra, and subject to change during and after the promo. After promo, or if any service is cancelled or downgraded, regular rates apply (subject to change). ©2022 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9626220468',
        extendedHighlights: ['Ultrafast'],
        highlights: [
          '12+ devices at a time',
          'Downloading for heavy usage activities',
          'Streaming with extreme speed',
          'Upload speeds up to 20 Mbps',
          'Download speeds up to 900 Mbps',
          '12+ devices at a time',
          'Downloading for heavy usage activities',
          'Streaming with extreme speed',
          'No minimum term contract required.',
        ],
        name: 'Ultrafast',
        pricing: {
          price: 70,
          charges: [
            {
              amount: 70,
              description: null,
              type: 'short_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 24,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
            {
              amount: 100,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: null,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: 24,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: 24,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: '2-year price guarantee, no term contract required',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight1',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Unlimited data with WiFi equipment included for 24 months',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight2',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Flex 4K streaming TV box and Peacock Premium available at no extra cost.',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight3',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Save $30/mo for 24 months when you add Xfinity Mobile',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight4',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: '2-year price guarantee, no term contract required',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: {
            name: 'Fast',
            downloadSpeed: {
              value: 300,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            uploadSpeed: {
              value: 10,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            type: 'fiber' as InternetProductType,
            features: null,
            __typename: 'ServiceabilityInternetProduct',
          },
          phone: null,
          tv: null,
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'SystemRequirements:System recommendations to run Xfinity Internet Service with speeds from 100 to 800 Mbps\r\nSoftware: Your desktop or laptop should be running a Microsoft mainstream-supported Windows operating system (on PC) or an Apple-supported OS X operating system (on Mac). You should use a recent version of one of the following browsers: Chrome, Firefox, Internet Explorer, or Safari.\r\nRelated Equipment: You can use a Cat5e or Cat6 Ethernet cable to facilitate your wired connection. A Cat5e Ethernet cable is not required for speeds up to 1 Gbps. Your desktop or laptop should have a 10/100/1000 Gigabit Ethernet interface or adapter for when you use a wired connection. Your computer or laptop also should have an Intel Core i5 (or faster) processor to support your speed.\r\nIf you have hardware, software and related equipment configurations that can connect to the Internet but that are different from the above recommendations, they will still work with your Xfinity Internet service; however, our testing has shown that these recommendations will work best to achieve your provisioned broadband speed. If you are not experiencing the speeds you expect, remember to test using a hardwired connection to the Internet. Also, make sure you are not in the process of downloading or uploading anything, close all programs using the Internet and power off any other devices that are sharing your home network. Remember, there are a number of external factors that may limit your speed; as a result, actual speeds may vary. For best performance, contact your device manufacturer/software provider for required upgrades, configuration updates, or troubleshooting guides.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'DetailsandRestrictions:Limited time offer. Restrictions apply. Not available in all areas. New residential customers only. Paperless Billing and Automatic Payments required. Discount will appear on statement within 45 days of enrollment. Equipment, installation, taxes and fees, and other applicable charges extra, and subject to change during and after the promo. After promo, or if any service is cancelled or downgraded, regular rates apply (subject to change). ©2022 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9626220474',
        extendedHighlights: ['Fast'],
        highlights: [
          'Up to 8 devices at a time',
          'Downloading shows/large files',
          'Streaming on multiple devices',
          'Upload speeds up to 10 Mbps',
          'Download speeds up to 300 Mbps',
          'Up to 8 devices at a time',
          'Downloading shows/large files',
          'Streaming on multiple devices',
          'No minimum term contract required.',
        ],
        name: 'Fast',
        pricing: {
          price: 50,
          charges: [
            {
              amount: 50,
              description: null,
              type: 'short_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 24,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
            {
              amount: 80,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: null,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: 24,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: 24,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: '2-year price guarantee, no term contract required',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight1',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Unlimited data with WiFi equipment included for 24 months',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight2',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Flex 4K streaming TV box and Peacock Premium available at no extra cost.',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight3',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Save $20/mo for 24 months when you add Xfinity Mobile',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight4',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: '2-year price guarantee, no term contract required',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: {
            name: 'Gigabit',
            downloadSpeed: {
              value: 1200,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            uploadSpeed: {
              value: 35,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            type: 'fiber' as InternetProductType,
            features: null,
            __typename: 'ServiceabilityInternetProduct',
          },
          phone: null,
          tv: {
            name: 'Ultimate TV',
            channels: {
              count: 185,
              __typename: 'ServiceabilityTvChannels',
            },
            features: null,
            additionalLanguages: null,
            type: 'cable' as TvProductType,
            __typename: 'ServiceabilityTvProduct',
          },
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'ContractTerms:The offer for the service package you have selected requires a minimum term contract. The contract will be sent to the mail or email address you provided. Under the contract, if you cancel all Xfinity services included with your package before the contract expires, except Xfinity Mobile, you will be billed the early termination fee specified in the contract, unless you cancel within the first 30 days after installation or activation, as applicable. You may cancel the contract by calling 1-800-Xfinity. If you cancel within 30 days of the date service is installed or activated, and return any equipment you have received from us, the early termination fee will not be charged. If you do not cancel the contract, you will automatically be billed and the terms of the minimum term contract will apply. Acceptance of these terms will occur at checkout.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'SystemRequirements:System recommendations to run Xfinity Internet Service with speeds up to 1 Gbps\r\nYour desktop or laptop should have the following:\r\n10/100/1000 Gigabit Ethernet interface or adapter for wired connection.\r\nStandard Cat5e Ethernet cable to facilitate a wired connection is recommended. Please be aware that due to Ethernet overhead on a 1G link, actual speeds might be limited to 940 Megabytes per second.\r\nCPU: Intel Dual Core i5 2.5GHz or better or AMD Dual Core K10 2.5GHz or better\r\nOS Requirements: Windows 8.1 (64 bit) or newer or Mac OSX 10.8 or newer\r\nMemory: 8 GB\r\nHard Drive: SSD recommended to achieve maximum download speeds\r\nHard Drive Space: 64 GB or larger\r\nMac OSX Browsers: Safari 6.2.8 or newer or Firefox 40.0.3 or newer or Chrome 44.0.2403 or newer\r\nWindows Browsers: IE 11 or newer; MS Edge browser (Windows 10); Firefox 40.0.3 or newer; Chrome 44.0.2403 or newer\r\nExample machines: Dell Latitude E7450, 2013 Macbook Pro\r\nIf you have hardware, software and related equipment configurations that can connect to the Internet but that are different from the above recommendations, they will still work with your Xfinity Internet service; however, our testing has shown that these recommendations will work best to achieve your provisioned broadband speed. If you are not experiencing the speeds you expect, remember to test using a hardwired connection to the Internet. Also, make sure you are not in the process of downloading or uploading anything, close all programs using the Internet and power off any other devices that are sharing your home network. Remember, there are a number of external factors that may limit your speed; as a result, actual speeds may vary. For best performance, contact your device manufacturer/software provider for required upgrades, configuration updates, or troubleshooting guides.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'DetailsandRestrictions:Limited time offer. Restrictions apply. Not available in all areas. New residential customers only. Paperless Billing and Automatic Payments required. Discount will appear on statement within 45 days of enrollment. Equipment, installation, taxes and fees, Broadcast TV Fee (up to $24.95/mo.), Regional Sports Fee (up to $19.15/mo.), and other applicable charges extra, and subject to change during and after the term contract. After term contract, or if any service is cancelled or downgraded, regular rates apply. ©2022 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9776105438',
        extendedHighlights: ['Ultimate TV', 'Gigabit'],
        highlights: [
          'Unlimited devices',
          "Downloading for today's ultra-connected household",
          'Streaming through the ultimate WiFi experience',
          'Multiplayer gaming',
          'Upload speeds up to 35 Mbps',
          'NFL Network, NBA TV, ESPNews',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Download speeds up to 1200 Mbps',
          'NFL Network, NBA TV, ESPNews',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Unlimited devices',
          "Downloading for today's ultra-connected household",
          'Streaming through the ultimate WiFi experience',
          'Multiplayer gaming',
          '1-year term contract required.',
        ],
        name: 'Premier Double Play',
        pricing: {
          price: 134.99,
          charges: [
            {
              amount: 134.99,
              description: null,
              type: 'short_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 12,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
            {
              amount: 170,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: null,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: 12,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: 12,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: 'Speed good for unlimited devices at the same time',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight3',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'HBO Max, SHOWTIME®, Hitz, and DVR service + HD (X1 TV box separate)',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight4',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Unlock $10/mo. off your Internet bill with an active Xfinity Mobile line',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight5',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'HBO Max, SHOWTIME®, Hitz, and DVR service + HD (X1 TV box separate)',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: {
            name: 'Connect',
            downloadSpeed: {
              value: 50,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            uploadSpeed: {
              value: 10,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            type: 'fiber' as InternetProductType,
            features: null,
            __typename: 'ServiceabilityInternetProduct',
          },
          phone: null,
          tv: null,
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'ContractTerms:The offer for the service package you have selected requires a minimum term contract. The contract will be sent to the mail or email address you provided. Under the contract, if you cancel all Xfinity services included with your package before the contract expires, except Xfinity Mobile, you will be billed the early termination fee specified in the contract, unless you cancel within the first 30 days after installation or activation, as applicable. You may cancel the contract by calling 1-800-Xfinity. If you cancel within 30 days of the date service is installed or activated, and return any equipment you have received from us, the early termination fee will not be charged. If you do not cancel the contract, you will automatically be billed and the terms of the minimum term contract will apply. Acceptance of these terms will occur at checkout.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'SystemRequirements:System recommendations to run Xfinity Internet Service with speeds from 50 to 75 Mbps\r\nSoftware: Your desktop or laptop should be running a Microsoft mainstream-supported Windows operating system (on PC) or an Apple-supported OS X operating system (on Mac). You should use a recent version of one of the following browsers: Chrome, Firefox, Internet Explorer, or Safari.\r\nRelated Equipment: You can use a Cat5e or Cat6 Ethernet cable to facilitate your wired connection. A Cat5e Ethernet cable is not required for speeds up to 1 Gbps. Your desktop or laptop should have a 10/100 Fast Ethernet interface or adapter for when you use a wired connection. Your computer or laptop also should have an Intel Core i5 (or faster) processor to support your speed.\r\nIf you have hardware, software and related equipment configurations that can connect to the Internet but that are different from the above recommendations, they will still work with your Xfinity Internet service; however, our testing has shown that these recommendations will work best to achieve your provisioned broadband speed. If you are not experiencing the speeds you expect, remember to test using a hardwired connection to the Internet. Also, make sure you are not in the process of downloading or uploading anything, close all programs using the Internet and power off any other devices that are sharing your home network. Remember, there are a number of external factors that may limit your speed; as a result, actual speeds may vary. For best performance, contact your device manufacturer/software provider for required upgrades, configuration updates, or troubleshooting guides.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'DetailsandRestrictions:Limited time offer. Restrictions apply. Not available in all areas. New residential customers only. Paperless Billing and Automatic Payments required. Discount will appear on statement within 45 days of enrollment. Equipment, installation, taxes and fees, and other applicable charges extra, and subject to change during and after the term contract. After term contract, or if any service is cancelled or downgraded, regular rates apply. ©2022 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9626100437',
        extendedHighlights: ['Connect'],
        highlights: [
          'Up to 4 devices at a time',
          'Downloading music/photos',
          'Stream content and video conferencing',
          'Upload speeds up to 10 Mbps',
          'Download speeds up to 50 Mbps',
          'Up to 4 devices at a time',
          'Downloading music/photos',
          'Stream content and video conferencing',
          '1-year term contract required.',
        ],
        name: 'Connect',
        pricing: {
          price: 19.99,
          charges: [
            {
              amount: 19.99,
              description: null,
              type: 'short_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 12,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
            {
              amount: 60,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: null,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: 12,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: 12,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: 'Speed good for up to 4 devices at the same time',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight3',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Option to add our Flex 4K streaming TV box + Voice Remote at no extra cost. Includes Peacock Premium.',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight4',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Option to add our Flex 4K streaming TV box + Voice Remote at no extra cost. Includes Peacock Premium.',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: {
            name: 'Connect More',
            downloadSpeed: {
              value: 100,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            uploadSpeed: {
              value: 10,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            type: 'fiber' as InternetProductType,
            features: null,
            __typename: 'ServiceabilityInternetProduct',
          },
          phone: null,
          tv: null,
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'ContractTerms:The offer for the service package you have selected requires a minimum term contract. The contract will be sent to the mail or email address you provided. Under the contract, if you cancel all Xfinity services included with your package before the contract expires, except Xfinity Mobile, you will be billed the early termination fee specified in the contract, unless you cancel within the first 30 days after installation or activation, as applicable. You may cancel the contract by calling 1-800-Xfinity. If you cancel within 30 days of the date service is installed or activated, and return any equipment you have received from us, the early termination fee will not be charged. If you do not cancel the contract, you will automatically be billed and the terms of the minimum term contract will apply. Acceptance of these terms will occur at checkout.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'SystemRequirements:System recommendations to run Xfinity Internet Service with speeds from 100 to 800 Mbps\r\nSoftware: Your desktop or laptop should be running a Microsoft mainstream-supported Windows operating system (on PC) or an Apple-supported OS X operating system (on Mac). You should use a recent version of one of the following browsers: Chrome, Firefox, Internet Explorer, or Safari.\r\nRelated Equipment: You can use a Cat5e or Cat6 Ethernet cable to facilitate your wired connection. A Cat5e Ethernet cable is not required for speeds up to 1 Gbps. Your desktop or laptop should have a 10/100/1000 Gigabit Ethernet interface or adapter for when you use a wired connection. Your computer or laptop also should have an Intel Core i5 (or faster) processor to support your speed.\r\nIf you have hardware, software and related equipment configurations that can connect to the Internet but that are different from the above recommendations, they will still work with your Xfinity Internet service; however, our testing has shown that these recommendations will work best to achieve your provisioned broadband speed. If you are not experiencing the speeds you expect, remember to test using a hardwired connection to the Internet. Also, make sure you are not in the process of downloading or uploading anything, close all programs using the Internet and power off any other devices that are sharing your home network. Remember, there are a number of external factors that may limit your speed; as a result, actual speeds may vary. For best performance, contact your device manufacturer/software provider for required upgrades, configuration updates, or troubleshooting guides.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'DetailsandRestrictions:Limited time offer. Restrictions apply. Not available in all areas. New residential customers only. Paperless Billing and Automatic Payments required. Discount will appear on statement within 45 days of enrollment. Equipment, installation, taxes and fees, and other applicable charges extra, and subject to change during and after the term contract. After term contract, or if any service is cancelled or downgraded, regular rates apply. ©2022 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9626100993',
        extendedHighlights: ['Connect More'],
        highlights: [
          'Up to 5 devices at a time',
          'Downloading music/photos',
          'Stream content and video conferencing',
          'Upload speeds up to 10 Mbps',
          'Download speeds up to 100 Mbps',
          'Up to 5 devices at a time',
          'Downloading music/photos',
          'Stream content and video conferencing',
          '1-year term contract required.',
        ],
        name: 'Connect More',
        pricing: {
          price: 39.99,
          charges: [
            {
              amount: 39.99,
              description: null,
              type: 'short_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 12,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
            {
              amount: 70,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: null,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: 12,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: 12,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: 'Speed good for up to 5 devices at the same time',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight3',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Option to add our Flex 4K streaming TV box + Voice Remote at no extra cost. Includes Peacock Premium.',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight4',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Option to add our Flex 4K streaming TV box + Voice Remote at no extra cost. Includes Peacock Premium.',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: null,
          phone: {
            name: 'Unlimited local calling',
            type: 'voip' as PhoneProductType,
            features: null,
            __typename: 'ServiceabilityPhoneProduct',
          },
          tv: null,
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'DetailsandRestrictions:Restrictions apply. Not available in all areas. New residential customers only. Paperless Billing and Automatic Payments required. Discount will appear on statement within 45 days of enrollment. Equipment, installation, taxes and fees, including regulatory recovery fees, and other applicable charges extra. Pricing subject to change. ©2022 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9627110068',
        extendedHighlights: ['Unlimited local calling'],
        highlights: [
          'Reliable home phone service',
          'Call with the best in clarity',
          'Call Waiting, Caller ID, Caller ID with Call Waiting',
          'Reliable home phone service',
          'Call with the best in clarity',
          'Call Waiting, Caller ID, Caller ID with Call Waiting',
          'No minimum term contract required.',
        ],
        name: 'Xfinity Unlimited Select',
        pricing: {
          price: 30,
          charges: [
            {
              amount: 30,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 0,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: null,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: null,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: 'Best call clarity',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight6',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: {
            name: 'Superfast',
            downloadSpeed: {
              value: 600,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            uploadSpeed: {
              value: 20,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            type: 'fiber' as InternetProductType,
            features: null,
            __typename: 'ServiceabilityInternetProduct',
          },
          phone: null,
          tv: {
            name: 'Ultimate TV',
            channels: {
              count: 185,
              __typename: 'ServiceabilityTvChannels',
            },
            features: null,
            additionalLanguages: null,
            type: 'cable' as TvProductType,
            __typename: 'ServiceabilityTvProduct',
          },
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'ContractTerms:The offer for the service package you have selected requires a minimum term contract. The contract will be sent to the mail or email address you provided. Under the contract, if you cancel all Xfinity services included with your package before the contract expires, except Xfinity Mobile, you will be billed the early termination fee specified in the contract, unless you cancel within the first 30 days after installation or activation, as applicable. You may cancel the contract by calling 1-800-Xfinity. If you cancel within 30 days of the date service is installed or activated, and return any equipment you have received from us, the early termination fee will not be charged. If you do not cancel the contract, you will automatically be billed and the terms of the minimum term contract will apply. Acceptance of these terms will occur at checkout.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'SystemRequirements:System recommendations to run Xfinity Internet Service with speeds from 100 to 800 Mbps\r\nSoftware: Your desktop or laptop should be running a Microsoft mainstream-supported Windows operating system (on PC) or an Apple-supported OS X operating system (on Mac). You should use a recent version of one of the following browsers: Chrome, Firefox, Internet Explorer, or Safari.\r\nRelated Equipment: You can use a Cat5e or Cat6 Ethernet cable to facilitate your wired connection. A Cat5e Ethernet cable is not required for speeds up to 1 Gbps. Your desktop or laptop should have a 10/100/1000 Gigabit Ethernet interface or adapter for when you use a wired connection. Your computer or laptop also should have an Intel Core i5 (or faster) processor to support your speed.\r\nIf you have hardware, software and related equipment configurations that can connect to the Internet but that are different from the above recommendations, they will still work with your Xfinity Internet service; however, our testing has shown that these recommendations will work best to achieve your provisioned broadband speed. If you are not experiencing the speeds you expect, remember to test using a hardwired connection to the Internet. Also, make sure you are not in the process of downloading or uploading anything, close all programs using the Internet and power off any other devices that are sharing your home network. Remember, there are a number of external factors that may limit your speed; as a result, actual speeds may vary. For best performance, contact your device manufacturer/software provider for required upgrades, configuration updates, or troubleshooting guides.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'DetailsandRestrictions:Limited time offer. Restrictions apply. Not available in all areas. New residential customers only. Paperless Billing and Automatic Payments required. Discount will appear on statement within 45 days of enrollment. Equipment, installation, taxes and fees, Broadcast TV Fee (up to $24.95/mo.), Regional Sports Fee (up to $19.15/mo.), and other applicable charges extra, and subject to change during and after the term contract. After term contract, or if any service is cancelled or downgraded, regular rates apply. ©2022 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9776102095',
        extendedHighlights: ['Ultimate TV', 'Superfast'],
        highlights: [
          'Up to 11 devices at a time',
          'Downloading shows quickly',
          'Streaming in HD on multiple devices',
          'Upload speeds up to 20 Mbps',
          'NFL Network, NBA TV, ESPNews',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Download speeds up to 600 Mbps',
          'NFL Network, NBA TV, ESPNews',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Up to 11 devices at a time',
          'Downloading shows quickly',
          'Streaming in HD on multiple devices',
          '1-year term contract required.',
        ],
        name: 'Ultimate Double Play',
        pricing: {
          price: 109.99,
          charges: [
            {
              amount: 109.99,
              description: null,
              type: 'short_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 12,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
            {
              amount: 140,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: null,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: 12,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: 12,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: 'Speed good for up to 11 devices at the same time',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight3',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: 'DVR service + HD (X1 TV box separate) ',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight4',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Unlock $10/mo. off your Internet bill with an active Xfinity Mobile line',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight5',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: 'DVR service + HD (X1 TV box separate) ',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: {
            name: 'Blast! Pro+',
            downloadSpeed: {
              value: 400,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            uploadSpeed: {
              value: 10,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            type: 'fiber' as InternetProductType,
            features: null,
            __typename: 'ServiceabilityInternetProduct',
          },
          phone: null,
          tv: null,
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'ContractTerms:The offer for the service package you have selected requires a minimum term agreement. The agreement will be sent to the mail or email address you provided. Under the agreement, if you cancel all Xfinity services included with your package before the agreement expires, except Xfinity Mobile, you will be billed the early termination fee specified in the agreement, unless you cancel within the first 30 days after installation or activation, as applicable. You may cancel the agreement by calling 1-800-Xfinity. If you cancel within 30 days of the date service is installed or activated, and return any equipment you have received from us, the early termination fee will not be charged. If you do not cancel the agreement, you will automatically be billed and the terms of the minimum term agreement will apply. Acceptance of these terms will occur at checkout.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'DetailsandRestrictions:Limited time offer. Restrictions apply. Not available in all areas. New residential customers only. Offer requires enrollment in both automatic payments and paperless billing. Must enroll within 30 days of placing the order. Without enrollment, the monthly service charge automatically increases by $10. The automatic payment and paperless billing discount is for 12 months and appears on bill within 30 days of enrolling. If either automatic payments or paperless billing are cancelled during the 12 month period, or if services are changed, the $10 monthly discount will be discontinued. Equipment, installation, taxes and fees, and other applicable charges extra, and subject to change during and after the term agreement. ©2021 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'SystemRequirements:System recommendations to run Xfinity Internet Service with speeds from 100 to 600 Mbps\nSoftware: Your desktop or laptop should be running a Microsoft mainstream-supported Windows operating system (on PC) or an Apple-supported OS X operating system (on Mac). You should use a recent version of one of the following browsers: Chrome, Firefox, Internet Explorer, or Safari.\nRelated Equipment: You can use a Cat5e or Cat6 Ethernet cable to facilitate your wired connection. A Cat5e Ethernet cable is not required for speeds up to 1 Gbps. Your desktop or laptop should have a 10/100/1000 Gigabit Ethernet interface or adapter for when you use a wired connection. Your computer or laptop also should have an Intel Core i5 (or faster) processor to support your speed.\nIf you have hardware, software and related equipment configurations that can connect to the Internet but that are different from the above recommendations, they will still work with your Xfinity Internet service; however, our testing has shown that these recommendations will work best to achieve your provisioned broadband speed. If you are not experiencing the speeds you expect, remember to test using a hardwired connection to the Internet. Also, make sure you are not in the process of downloading or uploading anything, close all programs using the Internet and power off any other devices that are sharing your home network. Remember, there are a number of external factors that may limit your speed; as a result, actual speeds may vary. For best performance, contact your device manufacturer/software provider for required upgrades, configuration updates, or troubleshooting guides.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9626101412',
        extendedHighlights: ['Blast! Pro+'],
        highlights: [
          'Up to 11 devices at a time',
          'Downloading shows quickly',
          'Streaming in HD on multiple devices',
          'Up to 11 devices at a time',
          'Downloading shows quickly',
          'Streaming in HD on multiple devices',
          '1-year term agreement required.',
          'Download speeds up to 400 Mbps',
          'Upload speeds up to 10 Mbps',
        ],
        name: 'Blast! Pro+',
        pricing: {
          price: 64.99,
          charges: [
            {
              amount: 64.99,
              description: null,
              type: 'short_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 12,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
            {
              amount: 80,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: null,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: 12,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: 12,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description:
              'Option to add our Flex 4K streaming TV box + Voice Remote at no extra cost. Includes Peacock Premium.',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight4',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: 'Speed good for up to 11 devices at the same time',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight6',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Option to add our Flex 4K streaming TV box + Voice Remote at no extra cost. Includes Peacock Premium.',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: null,
          phone: {
            name: 'Unlimited local calling',
            type: 'voip' as PhoneProductType,
            features: null,
            __typename: 'ServiceabilityPhoneProduct',
          },
          tv: {
            name: 'Popular TV',
            channels: {
              count: 125,
              __typename: 'ServiceabilityTvChannels',
            },
            features: null,
            additionalLanguages: null,
            type: 'cable' as TvProductType,
            __typename: 'ServiceabilityTvProduct',
          },
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'ContractTerms:The offer for the service package you have selected requires a minimum term contract. The contract will be sent to the mail or email address you provided. Under the contract, if you cancel all Xfinity services included with your package before the contract expires, except Xfinity Mobile, you will be billed the early termination fee specified in the contract, unless you cancel within the first 30 days after installation or activation, as applicable. You may cancel the contract by calling 1-800-Xfinity. If you cancel within 30 days of the date service is installed or activated, and return any equipment you have received from us, the early termination fee will not be charged. If you do not cancel the contract, you will automatically be billed and the terms of the minimum term contract will apply. Acceptance of these terms will occur at checkout.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'DetailsandRestrictions:Limited time offer. Restrictions apply. Not available in all areas. New residential customers only. Paperless Billing and Automatic Payments required. Discount will appear on statement within 45 days of enrollment. Equipment, installation, taxes and fees, including regulatory recovery fees, Broadcast TV Fee (up to $24.95/mo.), Regional Sports Fee (up to $19.15/mo.), and other applicable charges extra, and subject to change during and after the term contract. After term contract, or if any service is cancelled or downgraded, regular rates apply (subject to change). ©2022 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9777151359',
        extendedHighlights: ['Popular TV', 'Unlimited local calling'],
        highlights: [
          'Popular channels like FX, TLC, HGTV, Bravo',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Reliable home phone service',
          'Call with the best in clarity',
          'Call Waiting, Caller ID, Caller ID with Call Waiting',
          'Popular channels like FX, TLC, HGTV, Bravo',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Reliable home phone service',
          'Call with the best in clarity',
          'Call Waiting, Caller ID, Caller ID with Call Waiting',
          '2-year term contract required.',
        ],
        name: 'Popular TV & Unlimited Select',
        pricing: {
          price: 40,
          charges: [
            {
              amount: 40,
              description: null,
              type: 'short_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 24,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
            {
              amount: 70,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: null,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: 24,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: 24,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: 'Best call clarity',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight6',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: null,
          phone: null,
          tv: {
            name: 'Ultimate TV',
            channels: {
              count: 185,
              __typename: 'ServiceabilityTvChannels',
            },
            features: null,
            additionalLanguages: null,
            type: 'cable' as TvProductType,
            __typename: 'ServiceabilityTvProduct',
          },
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'ContractTerms:The offer for the service package you have selected requires a minimum term contract. The contract will be sent to the mail or email address you provided. Under the contract, if you cancel all Xfinity services included with your package before the contract expires, except Xfinity Mobile, you will be billed the early termination fee specified in the contract, unless you cancel within the first 30 days after installation or activation, as applicable. You may cancel the contract by calling 1-800-Xfinity. If you cancel within 30 days of the date service is installed or activated, and return any equipment you have received from us, the early termination fee will not be charged. If you do not cancel the contract, you will automatically be billed and the terms of the minimum term contract will apply. Acceptance of these terms will occur at checkout.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'DetailsandRestrictions:Limited time offer. Restrictions apply. Not available in all areas. New residential customers only. Paperless Billing and Automatic Payments required. Discount will appear on statement within 45 days of enrollment. Equipment, installation, taxes and fees, Broadcast TV Fee (up to $24.95/mo.), Regional Sports Fee (up to $19.15/mo.), and other applicable charges extra, and subject to change during and after the term contract. After term contract, or if any service is cancelled or downgraded, regular rates apply. ©2022 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9625110478',
        extendedHighlights: ['Ultimate TV'],
        highlights: [
          'NFL Network, NBA TV, ESPNews',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'NFL Network, NBA TV, ESPNews',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          '1-year term contract required.',
        ],
        name: 'Ultimate TV',
        pricing: {
          price: 59.99,
          charges: [
            {
              amount: 59.99,
              description: null,
              type: 'short_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 12,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
            {
              amount: 88.5,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: null,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: 12,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: 12,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: '20 Hour DVR service + HD (X1 TV box separate)',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight5',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: 'NFL Network, NBA TV, MLB Network',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight6',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: {
            name: 'Fast',
            downloadSpeed: {
              value: 300,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            uploadSpeed: {
              value: 10,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            type: 'fiber' as InternetProductType,
            features: null,
            __typename: 'ServiceabilityInternetProduct',
          },
          phone: null,
          tv: {
            name: 'Popular TV & Xfinity TV Latino',
            channels: {
              count: 175,
              __typename: 'ServiceabilityTvChannels',
            },
            features: null,
            additionalLanguages: null,
            type: 'cable' as TvProductType,
            __typename: 'ServiceabilityTvProduct',
          },
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'ContractTerms:The offer for the service package you have selected requires a minimum term contract. The contract will be sent to the mail or email address you provided. Under the contract, if you cancel all Xfinity services included with your package before the contract expires, except Xfinity Mobile, you will be billed the early termination fee specified in the contract, unless you cancel within the first 30 days after installation or activation, as applicable. You may cancel the contract by calling 1-800-Xfinity. If you cancel within 30 days of the date service is installed or activated, and return any equipment you have received from us, the early termination fee will not be charged. If you do not cancel the contract, you will automatically be billed and the terms of the minimum term contract will apply. Acceptance of these terms will occur at checkout.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'SystemRequirements:System recommendations to run Xfinity Internet Service with speeds from 100 to 800 Mbps\r\nSoftware: Your desktop or laptop should be running a Microsoft mainstream-supported Windows operating system (on PC) or an Apple-supported OS X operating system (on Mac). You should use a recent version of one of the following browsers: Chrome, Firefox, Internet Explorer, or Safari.\r\nRelated Equipment: You can use a Cat5e or Cat6 Ethernet cable to facilitate your wired connection. A Cat5e Ethernet cable is not required for speeds up to 1 Gbps. Your desktop or laptop should have a 10/100/1000 Gigabit Ethernet interface or adapter for when you use a wired connection. Your computer or laptop also should have an Intel Core i5 (or faster) processor to support your speed.\r\nIf you have hardware, software and related equipment configurations that can connect to the Internet but that are different from the above recommendations, they will still work with your Xfinity Internet service; however, our testing has shown that these recommendations will work best to achieve your provisioned broadband speed. If you are not experiencing the speeds you expect, remember to test using a hardwired connection to the Internet. Also, make sure you are not in the process of downloading or uploading anything, close all programs using the Internet and power off any other devices that are sharing your home network. Remember, there are a number of external factors that may limit your speed; as a result, actual speeds may vary. For best performance, contact your device manufacturer/software provider for required upgrades, configuration updates, or troubleshooting guides.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'DetailsandRestrictions:Limited time offer. Restrictions apply. Not available in all areas. New residential customers only. Paperless Billing and Automatic Payments required. Discount will appear on statement within 45 days of enrollment. Equipment, installation, taxes and fees, Broadcast TV Fee (up to $24.95/mo.), Regional Sports Fee (up to $19.15/mo.), and other applicable charges extra, and subject to change during and after the term contract. After term contract, or if any service is cancelled or downgraded, regular rates apply. ©2022 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9776101964',
        extendedHighlights: ['Popular TV & Xfinity TV Latino', 'Fast'],
        highlights: [
          'Up to 8 devices at a time',
          'Downloading shows/large files',
          'Streaming on multiple devices',
          'Upload speeds up to 10 Mbps',
          'Telemundo, TUDN, & UniMás',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Download speeds up to 300 Mbps',
          'Telemundo, TUDN, & UniMás',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Up to 8 devices at a time',
          'Downloading shows/large files',
          'Streaming on multiple devices',
          '1-year term contract required.',
        ],
        name: 'Popular Latino Double Play',
        pricing: {
          price: 79.99,
          charges: [
            {
              amount: 79.99,
              description: null,
              type: 'short_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 12,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
            {
              amount: 110,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: null,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: 12,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: 12,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: 'Speed good for up to 8 devices at the same time',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight3',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: '50+ Spanish-language channels',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight4',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: '20 Hour DVR service + HD (X1 TV box separate)',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight5',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: '50+ Spanish-language channels',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: {
            name: 'Connect More',
            downloadSpeed: {
              value: 100,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            uploadSpeed: {
              value: 10,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            type: 'fiber' as InternetProductType,
            features: null,
            __typename: 'ServiceabilityInternetProduct',
          },
          phone: null,
          tv: {
            name: 'Choice TV+',
            channels: {
              count: 10,
              __typename: 'ServiceabilityTvChannels',
            },
            features: null,
            additionalLanguages: null,
            type: 'cable' as TvProductType,
            __typename: 'ServiceabilityTvProduct',
          },
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'ContractTerms:The offer for the service package you have selected requires a minimum term contract. The contract will be sent to the mail or email address you provided. Under the contract, if you cancel all Xfinity services included with your package before the contract expires, except Xfinity Mobile, you will be billed the early termination fee specified in the contract, unless you cancel within the first 30 days after installation or activation, as applicable. You may cancel the contract by calling 1-800-Xfinity. If you cancel within 30 days of the date service is installed or activated, and return any equipment you have received from us, the early termination fee will not be charged. If you do not cancel the contract, you will automatically be billed and the terms of the minimum term contract will apply. Acceptance of these terms will occur at checkout.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'SystemRequirements:System recommendations to run Xfinity Internet Service with speeds from 100 to 800 Mbps\r\nSoftware: Your desktop or laptop should be running a Microsoft mainstream-supported Windows operating system (on PC) or an Apple-supported OS X operating system (on Mac). You should use a recent version of one of the following browsers: Chrome, Firefox, Internet Explorer, or Safari.\r\nRelated Equipment: You can use a Cat5e or Cat6 Ethernet cable to facilitate your wired connection. A Cat5e Ethernet cable is not required for speeds up to 1 Gbps. Your desktop or laptop should have a 10/100/1000 Gigabit Ethernet interface or adapter for when you use a wired connection. Your computer or laptop also should have an Intel Core i5 (or faster) processor to support your speed.\r\nIf you have hardware, software and related equipment configurations that can connect to the Internet but that are different from the above recommendations, they will still work with your Xfinity Internet service; however, our testing has shown that these recommendations will work best to achieve your provisioned broadband speed. If you are not experiencing the speeds you expect, remember to test using a hardwired connection to the Internet. Also, make sure you are not in the process of downloading or uploading anything, close all programs using the Internet and power off any other devices that are sharing your home network. Remember, there are a number of external factors that may limit your speed; as a result, actual speeds may vary. For best performance, contact your device manufacturer/software provider for required upgrades, configuration updates, or troubleshooting guides.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'DetailsandRestrictions:Limited time offer. Restrictions apply. Not available in all areas. New residential customers only. Paperless Billing and Automatic Payments required. Discount will appear on statement within 45 days of enrollment. Equipment, installation, taxes and fees, Broadcast TV Fee (up to $24.95/mo.), Regional Sports Fee (up to $19.15/mo.), and other applicable charges extra, and subject to change during and after the term contract. After term contract, or if any service is cancelled or downgraded, regular rates apply. ©2022 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9776102235',
        extendedHighlights: ['Choice TV+', 'Connect More'],
        highlights: [
          'Up to 5 devices at a time',
          'Downloading music/photos',
          'Stream content and video conferencing',
          'Upload speeds up to 10 Mbps',
          'Local channels like ABC, NBC, FOX',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Download speeds up to 100 Mbps',
          'Local channels like ABC, NBC, FOX',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Up to 5 devices at a time',
          'Downloading music/photos',
          'Stream content and video conferencing',
          '1-year term contract required.',
        ],
        name: 'Choice Double Play',
        pricing: {
          price: 49.99,
          charges: [
            {
              amount: 49.99,
              description: null,
              type: 'short_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 12,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
            {
              amount: 80,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: null,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: 12,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: 12,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: 'Speed good for up to 5 devices at the same time',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight3',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: 'Local channels like ABC, NBC, FOX',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight4',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: '20 Hour DVR service + HD (X1 TV box separate)',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight5',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: 'Local channels like ABC, NBC, FOX',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: {
            name: 'Connect More',
            downloadSpeed: {
              value: 100,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            uploadSpeed: {
              value: 10,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            type: 'fiber' as InternetProductType,
            features: null,
            __typename: 'ServiceabilityInternetProduct',
          },
          phone: null,
          tv: {
            name: 'Choice TV+ & Xfinity TV Latino',
            channels: {
              count: 60,
              __typename: 'ServiceabilityTvChannels',
            },
            features: null,
            additionalLanguages: null,
            type: 'cable' as TvProductType,
            __typename: 'ServiceabilityTvProduct',
          },
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'ContractTerms:The offer for the service package you have selected requires a minimum term contract. The contract will be sent to the mail or email address you provided. Under the contract, if you cancel all Xfinity services included with your package before the contract expires, except Xfinity Mobile, you will be billed the early termination fee specified in the contract, unless you cancel within the first 30 days after installation or activation, as applicable. You may cancel the contract by calling 1-800-Xfinity. If you cancel within 30 days of the date service is installed or activated, and return any equipment you have received from us, the early termination fee will not be charged. If you do not cancel the contract, you will automatically be billed and the terms of the minimum term contract will apply. Acceptance of these terms will occur at checkout.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'SystemRequirements:System recommendations to run Xfinity Internet Service with speeds from 100 to 800 Mbps\r\nSoftware: Your desktop or laptop should be running a Microsoft mainstream-supported Windows operating system (on PC) or an Apple-supported OS X operating system (on Mac). You should use a recent version of one of the following browsers: Chrome, Firefox, Internet Explorer, or Safari.\r\nRelated Equipment: You can use a Cat5e or Cat6 Ethernet cable to facilitate your wired connection. A Cat5e Ethernet cable is not required for speeds up to 1 Gbps. Your desktop or laptop should have a 10/100/1000 Gigabit Ethernet interface or adapter for when you use a wired connection. Your computer or laptop also should have an Intel Core i5 (or faster) processor to support your speed.\r\nIf you have hardware, software and related equipment configurations that can connect to the Internet but that are different from the above recommendations, they will still work with your Xfinity Internet service; however, our testing has shown that these recommendations will work best to achieve your provisioned broadband speed. If you are not experiencing the speeds you expect, remember to test using a hardwired connection to the Internet. Also, make sure you are not in the process of downloading or uploading anything, close all programs using the Internet and power off any other devices that are sharing your home network. Remember, there are a number of external factors that may limit your speed; as a result, actual speeds may vary. For best performance, contact your device manufacturer/software provider for required upgrades, configuration updates, or troubleshooting guides.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'DetailsandRestrictions:Limited time offer. Restrictions apply. Not available in all areas. New residential customers only. Paperless Billing and Automatic Payments required. Discount will appear on statement within 45 days of enrollment. Equipment, installation, taxes and fees, Broadcast TV Fee (up to $24.95/mo.), Regional Sports Fee (up to $19.15/mo.), and other applicable charges extra, and subject to change during and after the term contract. After term contract, or if any service is cancelled or downgraded, regular rates apply. ©2022 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9776105439',
        extendedHighlights: ['Choice TV+ & Xfinity TV Latino', 'Connect More'],
        highlights: [
          'Up to 5 devices at a time',
          'Downloading music/photos',
          'Stream content and video conferencing',
          'Upload speeds up to 10 Mbps',
          'Telemundo, TUDN, & UniMás',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Download speeds up to 100 Mbps',
          'Telemundo, TUDN, & UniMás',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Up to 5 devices at a time',
          'Downloading music/photos',
          'Stream content and video conferencing',
          '1-year term contract required.',
        ],
        name: 'Choice Latino Double Play',
        pricing: {
          price: 49.99,
          charges: [
            {
              amount: 49.99,
              description: null,
              type: 'short_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 12,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
            {
              amount: 80,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: null,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: 12,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: 12,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: 'Speed good for up to 5 devices at the same time',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight3',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: '50+ Spanish-language channels',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight4',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: '20 Hour DVR service + HD (X1 TV box separate)',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight5',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: '50+ Spanish-language channels',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: {
            name: 'Fast',
            downloadSpeed: {
              value: 300,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            uploadSpeed: {
              value: 10,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            type: 'fiber' as InternetProductType,
            features: null,
            __typename: 'ServiceabilityInternetProduct',
          },
          phone: null,
          tv: {
            name: 'Popular TV',
            channels: {
              count: 125,
              __typename: 'ServiceabilityTvChannels',
            },
            features: null,
            additionalLanguages: null,
            type: 'cable' as TvProductType,
            __typename: 'ServiceabilityTvProduct',
          },
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'ContractTerms:The offer for the service package you have selected requires a minimum term contract. The contract will be sent to the mail or email address you provided. Under the contract, if you cancel all Xfinity services included with your package before the contract expires, except Xfinity Mobile, you will be billed the early termination fee specified in the contract, unless you cancel within the first 30 days after installation or activation, as applicable. You may cancel the contract by calling 1-800-Xfinity. If you cancel within 30 days of the date service is installed or activated, and return any equipment you have received from us, the early termination fee will not be charged. If you do not cancel the contract, you will automatically be billed and the terms of the minimum term contract will apply. Acceptance of these terms will occur at checkout.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'SystemRequirements:System recommendations to run Xfinity Internet Service with speeds from 100 to 800 Mbps\r\nSoftware: Your desktop or laptop should be running a Microsoft mainstream-supported Windows operating system (on PC) or an Apple-supported OS X operating system (on Mac). You should use a recent version of one of the following browsers: Chrome, Firefox, Internet Explorer, or Safari.\r\nRelated Equipment: You can use a Cat5e or Cat6 Ethernet cable to facilitate your wired connection. A Cat5e Ethernet cable is not required for speeds up to 1 Gbps. Your desktop or laptop should have a 10/100/1000 Gigabit Ethernet interface or adapter for when you use a wired connection. Your computer or laptop also should have an Intel Core i5 (or faster) processor to support your speed.\r\nIf you have hardware, software and related equipment configurations that can connect to the Internet but that are different from the above recommendations, they will still work with your Xfinity Internet service; however, our testing has shown that these recommendations will work best to achieve your provisioned broadband speed. If you are not experiencing the speeds you expect, remember to test using a hardwired connection to the Internet. Also, make sure you are not in the process of downloading or uploading anything, close all programs using the Internet and power off any other devices that are sharing your home network. Remember, there are a number of external factors that may limit your speed; as a result, actual speeds may vary. For best performance, contact your device manufacturer/software provider for required upgrades, configuration updates, or troubleshooting guides.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'DetailsandRestrictions:Limited time offer. Restrictions apply. Not available in all areas. New residential customers only. Paperless Billing and Automatic Payments required. Discount will appear on statement within 45 days of enrollment. Equipment, installation, taxes and fees, Broadcast TV Fee (up to $24.95/mo.), Regional Sports Fee (up to $19.15/mo.), and other applicable charges extra, and subject to change during and after the term contract. After term contract, or if any service is cancelled or downgraded, regular rates apply. ©2022 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9776105437',
        extendedHighlights: ['Popular TV', 'Fast'],
        highlights: [
          'Up to 8 devices at a time',
          'Downloading shows/large files',
          'Streaming on multiple devices',
          'Upload speeds up to 10 Mbps',
          'Popular channels like FX, TLC, HGTV, Bravo',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Download speeds up to 300 Mbps',
          'Popular channels like FX, TLC, HGTV, Bravo',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Up to 8 devices at a time',
          'Downloading shows/large files',
          'Streaming on multiple devices',
          '1-year term contract required.',
        ],
        name: 'Popular Double Play',
        pricing: {
          price: 79.99,
          charges: [
            {
              amount: 79.99,
              description: null,
              type: 'short_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 12,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
            {
              amount: 110,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: null,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: 12,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: 12,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: 'Speed good for up to 8 devices at the same time',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight3',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: 'Popular channels like FX, TLC, HGTV, Bravo',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight4',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: '20 Hour DVR service + HD (X1 TV box separate)',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight5',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: 'Popular channels like FX, TLC, HGTV, Bravo',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
      {
        disclaimer: null,
        proximityDisclaimer: null,
        tags: null,
        metadata: null,
        products: {
          internet: {
            name: 'Gigabit',
            downloadSpeed: {
              value: 1000,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            uploadSpeed: {
              value: 35,
              unit: 'mbps' as ServiceabilityInternetSpeedUnit,
              __typename: 'ServiceabilityInternetSpeed',
            },
            type: 'fiber' as InternetProductType,
            features: null,
            __typename: 'ServiceabilityInternetProduct',
          },
          phone: {
            name: 'Unlimited local calling',
            type: 'voip' as PhoneProductType,
            features: null,
            __typename: 'ServiceabilityPhoneProduct',
          },
          tv: {
            name: 'Preferred+',
            channels: {
              count: 220,
              __typename: 'ServiceabilityTvChannels',
            },
            features: null,
            additionalLanguages: null,
            type: 'cable' as TvProductType,
            __typename: 'ServiceabilityTvProduct',
          },
          __typename: 'ServiceabilityTvInternetProducts',
        },
        __typename: 'TvInternetOffer',
        disclaimers: [
          {
            disclaimer:
              'DetailsandRestrictions:Restrictions apply. Not available in all areas. New residential customers only. Equipment, installation, taxes and fees, including regulatory recovery fees, Broadcast TV Fee (up to $19.45/mo.), Regional Sports Fee (up to $14.45/mo.) and other applicable charges extra. ©2021 Comcast. All rights reserved.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
          {
            disclaimer:
              'SystemRequirements:System recommendations to run Xfinity Internet Service with speeds up to 1 Gbps\nYour desktop or laptop should have the following:\n10/100/1000 Gigabit Ethernet interface or adapter for wired connection.\nStandard Cat5e Ethernet cable to facilitate a wired connection is recommended. Please be aware that due to Ethernet overhead on a 1G link, actual speeds might be limited to 940 Megabytes per second.\nCPU: Intel Dual Core i5 2.5GHz or better or AMD Dual Core K10 2.5GHz or better\nOS Requirements: Windows 8.1 (64 bit) or newer or Mac OSX 10.8 or newer\nMemory: 8 GB\nHard Drive: SSD recommended to achieve maximum download speeds\nHard Drive Space: 64 GB or larger\nMac OSX Browsers: Safari 6.2.8 or newer or Firefox 40.0.3 or newer or Chrome 44.0.2403 or newer\nWindows Browsers: IE 11 or newer; MS Edge browser (Windows 10); Firefox 40.0.3 or newer; Chrome 44.0.2403 or newer\nExample machines: Dell Latitude E7450, 2013 Macbook Pro\nIf you have hardware, software and related equipment configurations that can connect to the Internet but that are different from the above recommendations, they will still work with your Xfinity Internet service; however, our testing has shown that these recommendations will work best to achieve your provisioned broadband speed. If you are not experiencing the speeds you expect, remember to test using a hardwired connection to the Internet. Also, make sure you are not in the process of downloading or uploading anything, close all programs using the Internet and power off any other devices that are sharing your home network. Remember, there are a number of external factors that may limit your speed; as a result, actual speeds may vary. For best performance, contact your device manufacturer/software provider for required upgrades, configuration updates, or troubleshooting guides.',
            referenceMark: null,
            displayLocation:
              'details' as ServiceabilityDisclaimerDisplayLocation,
            __typename: 'ServiceabilityDisclaimer',
          },
        ],
        code: '9777153368',
        extendedHighlights: [
          'Preferred+',
          'Gigabit',
          'Unlimited local calling',
        ],
        highlights: [
          'NFL Network, NBA TV, MLB Network',
          'DVR service + HD (X1 TV box separate)',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Unlimited devices',
          "Downloading for today's ultra-connected household",
          'Streaming through the ultimate WiFi experience',
          'Reliable home phone service',
          'Call with the best in clarity',
          'Call Waiting, Caller ID, Caller ID with Call Waiting',
          'NFL Network, NBA TV, MLB Network',
          'DVR service + HD (X1 TV box separate)',
          'Thousands of hit shows, top movies, and more On Demand',
          'Download TV shows & movies with our Xfinity Stream app',
          'Unlimited devices',
          "Downloading for today's ultra-connected household",
          'Streaming through the ultimate WiFi experience',
          'Reliable home phone service',
          'Call with the best in clarity',
          'Call Waiting, Caller ID, Caller ID with Call Waiting',
          'No minimum term agreement required.',
          'Download speeds up to 1000 Mbps',
          'Upload speeds up to 35 Mbps',
        ],
        name: 'X1 Preferred Pro+ Triple Play with HBO Max',
        pricing: {
          price: 160,
          charges: [
            {
              amount: 160,
              description: null,
              type: 'long_term' as ServiceabilityChargeType,
              term: {
                unit: 'month' as ServiceabilityTermUnit,
                length: 0,
                __typename: 'ServiceabilityChargeTerm',
              },
              __typename: 'ServiceabilityCharge',
            },
          ],
          term: {
            unit: 'month' as ServiceabilityTermUnit,
            length: null,
            __typename: 'ServiceabilityPricingTerm',
          },
          __typename: 'ServiceabilityPricing',
        },
        contract: {
          term: {
            length: null,
            __typename: 'ServiceabilityContractTerm',
          },
          __typename: 'ServiceabilityContract',
        },
        promotions: [
          {
            description: 'HBO Max, SHOWTIME®',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight4',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: 'DVR service + HD (X1 TV box separate)',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight5',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description:
              'Unlock $10/mo. off your Internet bill with an active Xfinity Mobile line',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight6',
            __typename: 'ServiceabilityPromotion',
          },
          {
            description: 'HBO Max, SHOWTIME®',
            disclaimer: {
              disclaimer: null,
              displayLocation: null,
              referenceMark: null,
              __typename: 'ServiceabilityDisclaimer',
            },
            name: 'PromoHighlight',
            __typename: 'ServiceabilityPromotion',
          },
        ],
        providerCode: 'xfinity',
        providerOfferId: '9626100618',
      },
    ],
    __typename: 'ServiceabilityAvailableOffersOutput',
  },
};
