import { gql } from '@apollo/client';

export const normalizedAddress = gql`
  query NormalizedAddress($input: AddressInput!) {
    normalizedAddress(input: $input) {
      normalizedAddress {
        analysis
        city
        classification
        components
        metadata
        postalCode
        state
        status
        street
        unit
      }
    }
  }
`;
