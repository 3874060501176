import { useContext } from 'react';
import { useQual, useSessionStorage } from 'hooks';
import { MicrositeContext } from 'context';
import { providerAppSessionStorageKey } from '../constants';

export function usePhoneNumber({ isLeadMode = false } = {}): string {
  const [sessionStorageValue] = useSessionStorage(
    providerAppSessionStorageKey,
    {}
  );
  // Note that CallRail will *not* work unless the phone number matches
  // the "Swap Target" associated with this number group.  See:
  // https://app.callrail.com/settings/a/501092119/routing/calls-and-texts/numbers/903804480/edit?company_id=947929203

  const { phoneNumber: qualPhoneNumber } = useQual();
  const { phoneNumber: micrositePhoneNumber, leadModePhoneNumber } =
    useContext(MicrositeContext);

  // We have an additional requirement that we must use a different
  // phone number on the Lead Mode page after a user converts.  Note that
  // this phone number will *also* be "swapped" by CallRail:
  if (isLeadMode) {
    const { confirmationPhoneNumber } = sessionStorageValue;
    if (confirmationPhoneNumber) {
      console.log(
        `[usePhoneNumber] using s12y confirmation TFN ${confirmationPhoneNumber}`
      );
      return confirmationPhoneNumber;
    }
    if (!leadModePhoneNumber) {
      throw new Error(`No lead mode phone number in microsite config`);
    }
    console.log(`[usePhoneNumber] using fallback TFN ${leadModePhoneNumber}`);
    return leadModePhoneNumber;
  }

  // The microsites will usually send a `phoneNumber` value in the
  // JSON qual object.  in the event they don't for whatever reason,
  // use the default call center phone number on the provider's config.
  if (qualPhoneNumber) {
    return qualPhoneNumber;
  }

  // Default to the microsite config if all other conditions fail
  if (!micrositePhoneNumber) {
    throw new Error(`No phone number in microsite config`);
  }

  return micrositePhoneNumber;
}
